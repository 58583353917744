<template>
    <div class="mode_dark">
        <div class="breadcrumbs" :class="{ scrolling: scroll }">
            <div class="breadcrumbs__container">
                <ol class="breadcrumbs__breadcrumb">
                    <li class="breadcrumbs__breadcrumb__item link__item">
                        <router-link :to="'/'">Home</router-link>
                    </li>
                    <li v-if="this.title.path !== '/'" class="breadcrumbs__breadcrumb__item link__item">
                        <a href="#" @click.prevent="backBreadCrumb()">{{ title.name }}</a>
                    </li>
                    <li class="breadcrumbs__breadcrumb__item">
                        {{ project.product.title }}
                    </li>
                </ol>
            </div>
        </div>
        <div class="project-watch-head" :style="{ background: project.colorBackground }">
            <h1 class="project-watch-title container">{{ project.product.title }}</h1>
            <h4 v-if="project.product.title !== project.title" class="project-watch-title_sub">{{ project.title }}</h4>
        </div>
        <div class="project-watch-head__image" :style="project.colorBackground ? { background: `linear-gradient(180deg, ${project.colorBackground} 50%, white 50%)` } : null">
            <div class="container">
                <img v-webp:src="require(`@/assets/portfolio/spa-portfolio/head_product_images/${project.product.head_image}.webp`)" :src="require(`@/assets/portfolio/spa-portfolio/head_product_images/${project.product.head_image}.jpg`)" :alt="project.product.head_image" loading="lazy" />
            </div>
        </div>
        <section-layout name="about" title="About" id="about">
            <div v-if="project.product.desc" class="project-watch-technology" v-html="project.product.desc"></div>
            <div class="project-watch-icons">
                <img v-for="icon in project.product.icons" :key="icon" v-webp:src="require(`@/assets/portfolio/spa-portfolio/new_icons/${icon}.webp`)" :src="require(`@/assets/portfolio/spa-portfolio/new_icons/${icon}.jpg`)" :alt="icon" loading="lazy" />
            </div>
            <div class="project-watch__column-float_image" v-if="project.product.float_image">
                <img v-webp:src="require(`@/assets/portfolio/spa-portfolio/head_product_images/${project.product.float_image}.webp`)" :src="require(`@/assets/portfolio/spa-portfolio/head_product_images/${project.product.float_image}.png`)" :alt="project.product.float_image" loading="lazy" />
            </div>
            <div class="project-watch__column-text" v-html="project.product.text"></div>
        </section-layout>
        <div v-if="project.products" class="project-watch-products-group">
            <section-layout :name="product.title" :title="product.title" :id="product.title" v-for="product in project.products" :key="product.id">
                <div class="project-watch-products-group__desc" v-if="product.desc" v-html="product.desc"></div>
                <div class="project-watch-products-group__icons" v-if="product.icons">
                    <img v-for="icon in product.icons" :key="icon" v-webp:src="require(`@/assets/portfolio/spa-portfolio/new_icons/${icon}.webp`)" :src="require(`@/assets/portfolio/spa-portfolio/new_icons/${icon}.jpg`)" :alt="icon" loading="lazy" />
                </div>
                <div class="project-watch-products-group__text" v-html="product.text"></div>
            </section-layout>
        </div>
    </div>
</template>
<script>
import SectionLayout from "../../layouts/SectionLayout.vue";

export default {
    name: 'ModeDarkSPA',
    components: {
        SectionLayout
    },
    props: ['project', 'title', 'scroll'],
    mounted() {
        document.title = this.project.product.title
    },
    methods: {
        backBreadCrumb() {
            this.$router.push(this.title.path);
        },
    }
}
</script>
<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.scrolling {
    border-bottom: 1px solid $red;

    .breadcrumbs__breadcrumb {
        margin: 10px 0;
    }
}

.mode_dark {
    .project-watch-products-group {
        display: flex;
        flex-direction: column;
        gap: 120px;
        padding-bottom: 120px;

        .section_padding {
            padding: 0;
        }
    }
}
</style>
