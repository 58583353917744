<template>
  <section class="razzo-faq razzo-container">
    <h2 class="razzo-faq__title">{{ title }}</h2>
    <div class="accardion">
    <vue-collapsible-panel-group class="container">
    <vue-collapsible-panel :expanded="false" v-for="item in accordion" :key="item">
        <template #title>
            {{item.title}}

      <span class="vcp-icon">
       <img
        v-svg-inline
        :data-src="require('@/assets/icons/collapse-down.svg')"
        alt=""
      />
        </span>
        </template>
        <template #content>
            {{item.content}}
        </template>
    </vue-collapsible-panel>
    </vue-collapsible-panel-group>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel
} from '@dafcoe/vue-collapsible-panel'

export default {
  components:{
      VueCollapsiblePanelGroup,
      VueCollapsiblePanel
  },

  data() {
    return {
      title: "FAQ",
      accordion:[
        {
          title:'Do you store my data?',
          content:'We provide our services in strict compliance with General Data Protection Regulation (EU GDPR) rules. This means, Razzo ensures that all personal data is gathered legally and under strict conditions, protected from misuse and exploitation.'
        },
          {
          title:'Do you offer trial period?',
          content:'Yes, you can choose any subscription plan you like with a 14-day trial period. Cancel any time you want. No hidden fees, test our widgets and discover endless opportunities for promoting your eCommerce business.'
        },
         {
          title:'Can I switch from one tariff plan to another?',
          content:'You can choose a tariff plan in your personal account without any additional tech support. If you want to change the subscription plan, it will take no more than a couple of minutes - just select the tariff plan you like, and the system will automatically recalculate your subscription fee. You only pay for what are you actually using.'
        },
         {
          title:'How do I add your widgets to my website?',
          content:'To start using Razzo tools, all you need to do is connect it with your Magento store via Magento API keys..'
        }
        ,
         {
          title:'How long does it take to get my data synced?',
          content:'When you first upload the data, it can take from half of hour till several hours it all depends on the amount of data to sync. We develop the intelligent synchronization script, that adapts to Magento server limits and does not overload Magento, the same time it gets data in the most optimal way to  perform quick updates. '
        }
        ,
         {
          title:'How often my data are going to be updated?',
          content:'After the first synchronization the information will be automatically updated daily.'
        }
        ,
         {
          title:'Can I choose any period of time to compare the data?',
          content:'You can select any period of time for data analysis that is available according your subscription plan. The system will automatically select a similar previous period of time and display this data in widgets for comparison. Thus, in all widgets you can see the data of the current period compared to the previous period of the same duration.'
        }
        ,
         {
          title:'Do you partner only with Magento platform?',
          content:'As for now, yes. But we are working on the upgrade that would allow you to sync Razzo with other popular platforms like Shopify, Shopware and more.'
        }
      ]
    };
  },
  computed: {
    ...mapState(["magento_feedbacks"]),
  },
};
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.razzo-faq {
  background: $white;
  position: relative;
  padding: 0 0 60px;
  margin:0 auto;
  @include media(">=tablet") {
      padding: 0 0 120px;
  }
}
.razzo-faq__title {
  text-align: center;
  color: $black;
  font-size: 32px;
  margin-bottom: 32px;
  padding: 0 16px;
  @include media(">=tablet") {
    font-size: 40px;
  }
  &:before {
    content: "";
    display: block;
    width: 60px;
    height: 5px;
    background: $red;
    margin: 0 auto 20px;
  }
}
.accardion{
  padding:0;
  margin: 80px 0 0;
  @include media('>=tablet'){
    padding:0;
  }
  @include media('>=tablet-xl'){
    padding:0 20px 0;
  }
  @include media('>=widescreen'){
    padding:0 0 0;
  }

  .vcp{
    margin:12px 0;
    padding-bottom:12px;
    border-bottom:1px solid rgba(0, 0, 0, 0.12);
    @include media('>=widescreen'){
      margin:24px 0;
      padding-bottom:24px;
    }
  }
  .vcp__header{
    cursor:pointer;
    position:relative;
    display: flex;
    justify-content: space-between;
    &-title{
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color:$black;
      width:250px;
      @include media('>=tablet'){
        width:auto;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  .vcp__body{
    &-content{
      padding-top:16px;
      padding-right: 40px;
      font-size: 20px;
      line-height: 32px;
    }
  }
  .vcp__header-icon{
    span{
      display: none;
    }
  }
  .vcp-icon{
    position:absolute;
    top:0px;
    right:0px;
    transition:all 0.2s ease-in-out
  }
  .vcp.vcp--expanded.vcp--expandable{
    .vcp-icon{
      transform: rotate(180deg)
    }
  }
  .vcp--expandable:last-child{
    margin-bottom:0;
  }
}

</style>
