<template>
  <section class="mfo__banner">
    <img
        class="mfo__banner-img"
        src="@/assets/magento/magento-setup-tuning/magento-setup-tuning@2x.jpg"
        alt="Magento websites setup and tuning"
        loading="lazy"
        modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
    />
    <h1 class="mfo__banner-title">
      Magento websites setup and tuning
    </h1>
  </section>

  <section-layout name="mfo__first" title="" margin="true">
    <p>These days Magento is a widely used eCommerce platform, with an ever-growing community. WDevs strives to create custom eCommerce websites based on your industry specifics and business goals. Our web development professionals, with years of in-depth expertise in commercial web development technologies and a client value-oriented approach, are ready to offer you efficient, multi-pronged and swift solutions tailored specifically  to your needs.</p>
    <p>Magento setup & tuning is a gradual process that suggests the following steps:</p>
  </section-layout>

  <section-layout name="mfo__assistance" title="Assistance in selecting the optimal hosting solution" margin="true">
    <p>The first step in creating your Magento website is finding reliable & secure Magento hosting. Given the large number of options available on the market, choosing the best option for your project is not necessarily an easy task, which is why our team offers you comprehensive support in finding and setting up hosting for your future site, taking all the Magento features and technical requirements into account.</p>
    <p>We have long-trusted relationships with key Magento hosting providers & are ready to provide you with full support for any site issues or requests. Our team will analyze your eCommerce store, your goals, the Magento stack, as well as the operating system setup. Whether it’s a dedicated server or a VPS, we will help you to ensure compatibility with all your Magento requirements, host Magento files on your server, install a Magento SSL certificate and enable correct redirection if necessary.</p>
  </section-layout>

  <section-layout name="mfo__deployment" title="Magento sites deployment" margin="true">
    <p>Deploying and configuring Magento correctly and efficiently is a key point for a future website’s high performance, and thus reflects on the  business’s success. We are experts in nearly all types of servers and their peculiarities with regards to operating Magento.</p>
    <p>Basically, the deployment of Magento on a chosen hosting service consists of the following steps:</p>
    <ul>
      <li>Preliminary hosting configuration (setting up hosting software and its specific configuration)</li>
      <li>Copying Magento files</li>
      <li>Database creation and access configuration</li>
      <li>Deploying the Magento instance</li>
      <li>Importing sample data if needed</li>
      <li>Configuring all necessary Magento parameters</li>
    </ul>
    <p>We offer an automated, faster to scale, fault-immune deployment process; our custom deployment tools allow us to thoroughly configure your store website in the set-up environment, we configure service connections like database, Redis, etc., test to prevent extended downtime and also review the deployment status messages, and mitigate issues if necessary.</p>
  </section-layout>

  <section-layout name="mfo__themes" title="Magento Custom Themes" margin="true">
    <h3>Design</h3>
    <p>Magento Custom Themes is a well-designed mix of custom templates, layouts, styles, and images to provide your website with a substantial look and create the  right atmosphere  for your customers to understand your brand & therefore purchase your products. We can create an eye-catching and recognizable style to help your website stand out from numerous identical eCommerce stores. Firstly, we analyze your business needs, your vision & target audience to translate your brand identity into value-added visuals & functionality.  We then develop some ideas & layouts to discuss with you, before our creative team develops your unique theme design, with an eye to  future tech installation  requirements.</p>
    <h3>Themes setup</h3>
    <p>We prepare a detailed roadmap for theme installation; featuring all necessary elements, 3rd party modules and extensions. Our team checks for compatibility of all features & provides complete performance optimization. We also understand if you would like to import your current ready-made design to  your new Magento website - our WDevs team knows how to seamlessly convert your ideas into Magento-matching  themes.</p>
    <h3>Optimization</h3>
    <p>We perform complete performance optimization & testing of Magento themes installed, including code, JS, graphics, UI/UX, mobile-responsiveness, redirects, server settings & functionality checks to ensure a higher page loading speed and guarantee an enjoyable shopping experience for your customers.</p>
    <h3>Customization</h3>
    <p>Looking for a custom-tailored solution for your website?</p>
    <p>Say no more!  Our Magento pros will help you to build an exclusive website theme reflecting your company’s values & mission. Custom features, various Magento extensions & module integration, complete setup & configuration, as well as access to our advanced support. Our team creates cutting-edge solutions for your business with outstanding functionality.</p>
  </section-layout>

  <section-layout name="mfo__installation" title="Magento installation on cloud-based services" margin="true">
    <p>WDevs offers a full set of tools and services for developing Magento websites on cloud-based services. Our team of experienced Magento developers will assist you with all the steps integral to your Magento-based project, or with migration of your existing store to the cloud. We will discuss  all the possible options for PaaS/IaaS Cloud Environments, build a site architecture plan for further implementation, as well as provide a complete Magento setup & deployment on a chosen cloud-based service, gradual integration of existing or new ERP, Payment/Shipping modules, QA, and full tech maintenance support.</p>
  </section-layout>

  <section-layout name="mfo__multi-language" title="Multi-language setup" margin="true">
    <p>For the international audience, Magento is designed to store the multi-language functionality -  which is very often a must-have feature, however this requires the significant effort of a qualified specialist in order for the setup to work correctly, be SEO-friendly and not bring Magento clients  more headaches than advantages.</p>
    <p>
      One of the typical scenarios for the multi-language setup is as follows:
    </p>
    <ul>
      <li>Create a Magento Store View for each language</li>
      <li>Properly configure each Store View</li>
      <li>Reindex and clear the Magento cache</li>
      <li>Setup and enable respective language packs</li>
      <li>Translate category and product pages</li>
      <li>Translate product attributes and options</li>
      <li>Translate CMS pages and blocks</li>
      <li>Translate email templates</li>
      <li>Improve the Multi-Language setup by installing useful extensions</li>
    </ul>
  </section-layout>

  <section-layout name="mfo__multi-domain" title="Multi-Domain setup" margin="true">
    <p>Having a Multi-Domain Magento store is another way to serve different sales channels or customer audiences, with maximum efficiency. That’s where Magento stands out from competing ecommerce systems and is designed to support this out-of-the-box feature.</p>
    <p>However, like with the multi-language setup, configuring a Multi-Domain system requires respective administration and development skills, and that’s why our team of professional developers with a vast pool of accumulated experience, is your best choice!</p>
  </section-layout>

  <section-layout name="mfo__payment" title="Integrating various payment gateways" margin="true">
    <p>By default, Magento has the most popular payment gateways (PayPal, BrainTree and Authorize.net), which can be enabled and configured with barely any effort - but it may happen that your business could benefit from using some lesser known payment gateways, or even possibly a gateway from your local bank.</p>
    <p>In some cases, it’s possible to obtain or purchase and then set up the respective payment module extension provided by its owner’s bank or payment company. Once this is done, it is left to us to configure it properly; according to the provider guidelines, run necessary tests, and then finally hand it over to the client to profit from its use as customers make purchases.</p>
    <p>In other cases, the payment gateway provider which is suitable for a Magento business’s needs, may not have a payment module available yet, and this is where an option for a custom built payment module comes in.</p>
    <p>Wdevs has vast  experience in building payment modules for Magento, such as various simpler, and sometimes complicated solutions;  including online loan approval directly on the Checkout page, splitting a payment, as well as other solutions which increase sales.</p>
  </section-layout>

  <section-layout name="mfo__extensions" title="Suggesting the most appropriate 3rd party extensions and assisting in setting them up" margin="true">
    <p>In many cases, as already mentioned, it’s worth considering existing solutions for many customization needs, however, the ready-made solutions market is wide and it’s very important to know the quirks and peculiarities of custom software provided by certain software vendors, as well as setting-up those solutions correctly.</p>
    <p>Wdevs has many years of experience in setting up, configuring, and customizing Magento extensions from most leading extension vendors. Moreover, we work as a custom development team for a number of well-known Magento extensions vendors. With some of them, we operate under a white-label; for others, we work as development partners.</p>
    <p>The key is that since we have been working on a multitude of custom development requests for the extensions of many vendors, we have the ultimate acquired knowledge regarding the pros and cons of many extensions, and we are most definitely the right team to make suggestions to you about which extensions are worth being used, and which are better trashed to the recycle bin.</p>
    <p>Our specialists have a vast combined pool of knowledge about hundreds of extensions and their evolution, so we are more than capable of  advising  you about what’s best in each individual case and helping with the setup, as well as assisting with  any customization required.</p>
    <a href="#" @click.prevent="goBack()" class="button primary back">Back to Magento</a>
  </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";

export default {
  data(){
    return{
      position:null,
    }
  },
  components: {
    SectionLayout,
  },
  methods:{
    goBack(){
      if(!document.referrer){
        Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/magento` }})
        this.$router.push('/magento')
      }else{
        this.$router.go(-1)
      }
    }
  },
  created(){
    window.history.scrollRestoration = "auto";
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.mfo {
  &__banner {
    height: 300px;
    position: relative;
    @include media(">=tablet") {
      height: 500px;
    }
    @include media(">=desktop") {
      height: 640px;
      margin-bottom: 60px;
    }
    &-img,
    picture {
      display: block;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
      bottom: 40px;
      font-size: 40px;
      left: 40px;
      max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
      bottom: 80px;
      left: 40px;
      font-size: 56px;
      max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
      bottom: 80px;
      left: 80px;
      font-size: 64px;
      max-width: 1150px;
    }
  }

  &__first,
  &__assistance,
  &__deployment,
  &__themes,
  &__installation,
  &__multi-language,
  &__multi-domain,
  &__payment,
  &__extensions {
    .section_content {
      display: block;
    }
    ul{
      li{
        margin-bottom:5px;
      }
    }
    a {
      color: $red;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .button{
      cursor: pointer;
      margin-top:40px;
      color:$white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      padding: 10px 24px;
      border-radius:26px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
</style>
