<template>
  <ReferencesSliderSection dark='' magento='' :feedbacks="razzo_feedbacks" title="Clients’ cases" razzo='razzo'/>
</template>

<script>
import ReferencesSliderSection from "../ReferencesSliderSection.vue";
import { mapState } from "vuex";

export default {
 components:{
    ReferencesSliderSection
  },
  computed: {
    ...mapState(["razzo_feedbacks"]),
  },
};
</script>
