<template>
  <section-layout name="why-magento" mode="dark" title="Why Magento">
    <p class="why-magento__intro">
    It doesn't make sense to invent something that already exists - there are many open-source e-commerce platforms already available.<br/>
    But which one to choose? There are so many great platforms already out there.<br/>For us, the decision is obvious - it's Magento<br/>
    Why?<br/>
    Let’s look at this in detail:
    </p>

    <div class="column">
      <div class="why-magento_item">
        <h3 class="why-magento_title">Expertise</h3>
        <div class="why-magento_content">
          <p>
           Probably the  number one reason is that we are Magento experts.  We have a strong Magento team with a phenomenal combined skills set which has been  proven over the years on our <a href='/our-story#magento-journey'>Magento journey</a>.
          </p>
        </div>
      </div>
      <div class="why-magento_item">
        <h3 class="why-magento_title">Reliability</h3>
        <div class="why-magento_content">
          <p>
           There is no perfection, and some platforms may look better than Magento at first glance,  but Magento is a veteran in the e-commerce platform market. It has existed since 2008 (as have we) which confirms that Magento is reliable and has proven the test of time. Moreover, there are so many 3rd party extensions and themes, so you have access to unlimited customization abilities almost effortlessly.
          </p>
        </div>
      </div>
    </div>

    <div class="column">
      <div class="why-magento_item">
        <h3 class="why-magento_title">Competitiveness</h3>
        <div class="why-magento_content">
          <p>
            Magento has its own pros and cons. There are so many competitors. Some are really worthy,  but it would be wise  to take a deeper look:
          </p>
          <ul>
            <li>
             Shopify is perfect, quick, and reliable. If you are happy with what Shopify provides out of the box - it's a great choice. However, it's very limited in customization and it's hard to change it to satisfy your needs
            </li>
            <li>
             BigCommerce is the next great competitor, however, it's still limited in customization as well, and there is no free version.
            </li>
            <li>
            Prestashop is great for small shops, though we wouldn't recommend considering it for multi-stores and for something more serious than just selling simple products online
            </li>
            <li>
              Drupal, Joomla, Woocommerce... they are great platforms, though we'd rather consider them to be  CMS, and not a fully-fledged e-commerce platform. 
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p class="why-magento__intro__end">
   There are many other platforms and they mostly have value, but after trying to work with many e-commerce platforms, we have decided to focus on Magento in terms of e-commerce.  
    <br/>
    <br/>
    <strong>After all, it's great to stay on track  when we are already on a successful path </strong> &#128521;
    </p>
  </section-layout>
</template>
<script>
import SectionLayout from "@/layouts/SectionLayout";
export default {
  components: {
    SectionLayout,
  },
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.why-magento {
  .title {
    &:before {
      background: $orange;
    }
  }
  .section_content {
    flex-wrap: wrap;
  }
}
.why-magento__intro {
  width: 100%;
  margin-bottom: 0;
  &__end{
  width: 100%;
  margin-top: 40px;
  @include media('>=tablet'){
    margin-top: 80px;
  }
  @include media('>=desktop'){
    margin-top: 48px;
  }
  }
}

.why-magento_item {
  border-top: 1px solid $white;
  margin-top: 40px;
  @include media(">=tablet") {
    margin-top: 80px;
  }
}
.why-magento_title {
  margin: 40px 0 20px;
  @include media(">=tablet") {
    margin: 40px 0 20px;
  }
}
.why-magento_content {
  p {
    margin-bottom: 20px;
    @include media(">=tablet") {
      margin-bottom: 32px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: $orange;
  }
  ul {
    @extend %listreset;
    padding-left: 30px;
    list-style: disc;
  }
  li {
    margin-bottom: 20px;
    @include media(">=tablet") {
      margin-bottom: 32px;
    }
  }
}
</style>
