<template>
  <div class="razzo-contact-form-container">
    <div class="razzo-contact-form">
      <button
          class="razzo-contact-form-close"
          aria-label="close button"
          @click="submission.isSent=false"
      >
        <img
            v-svg-inline
            :data-src="require('@/assets/icons/close-modal.svg')"
            alt=""
        />
      </button>

      <div class="razzo-contact-form-header">
        <h2>Have any questions about Razzo?</h2>
        <div class="razzo-contact-form-description">
          {{ description }}
        </div>
      </div>

      <div class="razzo-contact-form-header" v-if="submission.isSent">
        <div class="razzo-contact-form-description"
             :class="{
          error: submission.hasError,
          success: !submission.hasError
        }">
          {{ submission.messageText.split('! ')[0] }}!<br/>
          {{ submission.messageText.split('! ')[1] }}
        </div>
      </div>

      <template v-else>
        <RazzoForm :description="description" @onFormSubmission="onFormSubmission"/>
      </template>

    </div>
  </div>
</template>
<script>
import RazzoForm from "../Form/RazzoForm.vue";
export default {
    name: "FormContact",
    data() {
        return {
            submission: this.getDefaultSubmission(),
            description: "Contact our specialist for a quick guide and more information about our product."
        };
    },
    components: { RazzoForm },
    methods: {
    getDefaultSubmission() {
      return {
        isSent: false,
        hasError: false,
        messageText: ''
      }
    },
    onFormSubmission(payload) {
      Object.assign(this.submission, this.getDefaultSubmission(), payload);
    }
  }
};
</script>

<style lang="scss">
@import "../Form/form-styles.scss";
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.razzo-contact-form-container{
    background: $violet;
    padding:120px 0;
    .razzo-contact-form{
        position:relative;
        margin: 0 auto;
        width:300px;
        background: $white;
        padding:60px 20px 30px;
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.4);
        border-radius: 16px;
         @include media('>=m-phone'){
           width:350px;
           padding:50px 40px 50px;
        }
        @include media('>=phone'){
           width:450px;
           padding:50px 20px 50px;
        }
        @include media('>=tablet'){
           width:576px;
           padding:72px 48px 48px;
        }
        h2{
            margin:0 0 10px;
        }
        &-description{
           margin:0 0 24px; 
           line-height: 30px;
           &.success {
             color: $red;
           }
           &.error {
             color: red;
           }
        }
        &-close{
          background: none;
          border:none;
          position:absolute;
          top:20px;
          right: 15px;
          &:hover{
            cursor:pointer;
          }
           @include media('>=tablet'){
            top:36px;
            right: 36px;
          }
        }
    }
}
</style>
