<template>
  <section class="mfo__banner">
    <a href="/magento-security-services" class="cloud" aria-label="Ultimate security check for only $600">
      <img
          v-svg-inline
          :data-src="require('@/assets/icons/cloud.svg')"
          alt=""
          class="cloud-img"
      />
    </a>
    <img
        class="mfo__banner-img"
        src="@/assets/magento/magento-support-security/magento-support-security.jpg"
        alt="Magento Support and Security Patching"
        loading="lazy"
        modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
    />
    <h1 class="mfo__banner-title">
      Magento Support and Security Patching
    </h1>
  </section>

  <section-layout name="mfo__first" title="" margin="true">
    <p>Magento releases regular security patches to fix the security flaws & help you protect your website from any kind of malwares & cybersecurity threats. By installing these patches of code, we can upgrade your e-store security & prevent any types of intrusions on your eCommerce platform.</p>
  </section-layout>

  <section-layout name="mfo__work" title="How we work?" margin="true">
    <p>Timely installation of Magento security patches is a must to keep your webstore data safe. We offer a professional approach that includes several necessary & consistent steps:</p>
    <ul>
      <li>We check for the latest updates to install</li>
      <li>Make an advance backup of your Magento store</li>
      <li>Use the access to Log into client’s admin panel, disable ‘Compilation’, and clear the cache to check to see whether everything runs smoothly and efficiently</li>
      <li>Create a sandbox copy of all the files to which the security patches are to be applied</li>
      <li>Apply the security patches to the sandbox file copies</li>
      <li>Apply the patches to the website </li>
    </ul>
  </section-layout>

  <section-layout name="mfo__support" title="All-round support for your website" margin="true">
    <p>Even if your website is secure, you need to maintain & upgrade it on a regular basis. WDevs performs all typical maintenance on your web store - our certified Magento specialists are ready to provide and execute the best solutions for your business. We carry out regular site audits to improve overall performance and upgrade your site on a timely basis to boost the  conversion rate, & subsequently - your sales.</p>
  </section-layout>

  <section-layout name="mfo__best-experience" title="Offering the best experience for our clients" margin="true">
    <p>Reducing the risks today can save your eCommerce business’s reputation & drastically cut your expenses in the future. By choosing us as your trusted web dev agency, you are provided with  professional experience, as well as the tried-and-tested results needed for a fast and securely run  website.</p>
    <a href="#" @click.prevent="goBack()" class="button primary back">Back to Magento</a>
  </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";

export default {
  data(){
    return{
      position:null,
    }
  },
  components: {
    SectionLayout,
  },
  methods:{
    goBack(){
      if(!document.referrer){
        Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/magento` }})
        this.$router.push('/magento')
      }else{
        this.$router.go(-1)
      }
    }
  },
  created(){
    window.history.scrollRestoration = "auto";
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.mfo {
  &__banner {
    height: 300px;
    position: relative;
    @include media(">=tablet") {
      height: 500px;
    }
    @include media(">=desktop") {
      height: 640px;
      margin-bottom: 60px;
    }
    &-img,
    picture {
      display: block;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cloud {
      position: absolute;
      z-index: 100;
      top: 20px;
      right: 20px;
      width: 130px;
      height: 130px;
      user-select: none;
      @media all and (min-width: 768px) {
        width: 200px;
        height: 200px;
      }
      @media all and (min-width: 992px) {
        width: 230px;
        height: 230px;
      }
      @media all and (min-width: 1100px) {
        width: 260px;
        height: 260px;
      }
      &-img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
      bottom: 40px;
      font-size: 40px;
      left: 40px;
      max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
      bottom: 80px;
      left: 40px;
      font-size: 56px;
      max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
      bottom: 80px;
      left: 80px;
      font-size: 64px;
      max-width: 1150px;
    }
  }

  &__first,
  &__support,
  &__best-experience,
  &__work {
    .section_content {
      display: block;
    }
    ul{
      li{
        margin-bottom:5px;
      }
    }
    a {
      color: $red;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .button{
      cursor: pointer;
      margin-top:40px;
      color:$white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      padding: 10px 24px;
      border-radius:26px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
</style>
