<template>
  <div class="project-watch" @scroll="scrolling()">
    <ModeDarkSPA
      v-if="project.mode_dark"
      :project="project"
      :title="title"
      :scroll="scroll"
    />
    <ModeLightSPA
      v-if="!project.mode_dark"
      :project="project"
      :title="title"
      :scroll="scroll"
    />
 </div>


    <div class="buttons container button-group" v-if="getPrev() !=='/portfolio'&&(referrer||history!==null)">
    <div></div>
    <div class="group">
      <a href="#" @click.prevent="goBack()" class="primary btn-back">Back</a>
  <div
              class="btn-read-more primary contacts-button small"
              id="show-modal"
              @click="this.$router.push('/portfolio')"
            >
              <span class="button-content">
                <span class="icon"
                  ><img
                    v-svg-inline
                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                    alt=""
                /></span>
                 See more in our portfolio
              </span>
            </div>

<!--
      <a
        href="#"
        @click.prevent="$router.push('/portfolio')"
        class="primary button back"
        >See more in our portfolio</a
      > -->
      </div>
    </div>
      <div class="buttons container button-alone" v-else>
        <div></div>
        <div class="alone">

    <div
     v-if="getPrev() !== '/portfolio'&&referrer"
              class="btn-read-more primary contacts-button small"
              id="show-modal"
              @click.prevent="$router.push('/portfolio')"
            >
              <span class="button-content">
                <span class="icon"
                  ><img
                    v-svg-inline
                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                    alt=""
                /></span>
                 See more in our portfolio
              </span>
            </div>

<!--
      <a
        href="#"
        v-if="this.$route.params.way !== 'portfolio'&&referrer"
        @click.prevent="$router.push('/portfolio')"
        class="primary button back"
        >See more in our portfolio</a
      > -->
      <!-- <a v-else href="#" @click.prevent="goBack()" class="primary button back">See more in our portfolio</a> -->
           <div
              class="btn-read-more primary contacts-button small"
              id="show-modal"
               v-else href="#" @click.prevent="goBack()"
            >
              <span class="button-content">
                <span class="icon"
                  ><img
                    v-svg-inline
                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                    alt=""
                /></span>
                 See more in our portfolio
              </span>
            </div>
      </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import ModeDarkSPA from "./PortfolioProjectWatchSPA/ModeDarkSPA.vue";
import ModeLightSPA from "./PortfolioProjectWatchSPA/ModeLightSPA.vue";

export default {
  components: {
    ModeDarkSPA,
    ModeLightSPA
  },
  data() {
    return {
      scroll: false,
      title: "",
      referrer:document.referrer,
      history:window.history.state.back
    };
  },
  computed: {
    ...mapState(["hitech_projects", "mob_projects", "other_projects", "menu"]),
     allProjects(){
      return [...this.hitech_projects,...this.mob_projects,...this.other_projects]
    },
    project() {
      return this.allProjects.find(
        (project) => project.id === this.$route.params.id
      );
    },
    findAboutChildren() {
      return this.menu.find((item) => item.path === `/about`);
    },
  },
  methods: {

    getPrev(){
      let ret = this.$router.options.history.state.back;
      if (!ret && document.referrer) {
        const url = new URL(document.referrer)
        ret = url.pathname
      }
      return ret;
    },

    goBack(){
      if(!document.referrer&&window.history.state.back===null){
        Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/portfolio` }})
        this.$router.push('/portfolio')
      }else{
        this.$router.go(-1)
      }
    },
    backCrumb() {
      const prev = this.getPrev();

      let portfolioBackTitle = this.menu.find(
        (item) => item.path === prev
      );
      if (portfolioBackTitle) {
        this.title = portfolioBackTitle;
      } else {
        this.title = this.findAboutChildren.children.find(
          (child) => child.path === prev
        );
      }

      if (!this.title && prev =='/' ) {
        this.title = {
          "path": "/",
          "name": "Featured Projects"
        }
      }

      if (!this.title) {
        this.title = {
          "path": "/portfolio",
          "name": "Portfolio"
        }
      }
    },
    scrolling() {
      const scroll = document.documentElement.scrollTop;
      if (scroll > 50) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.scrolling);
  },
  mounted() {
    this.backCrumb();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.btn-back{
  color:$white;
  border-radius: 26px;
  padding: 8px 16px 3px;
  background: $red;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
  width: max-content;
   background-image: linear-gradient($darkRed, $darkRed);
      background-repeat: no-repeat;
      transition: background-size 0s, color 0s;
      background-size: 0% 100%;
      @include media(">=tablet-xl") {
        padding: 12px 30px 8px;
      }
      &:hover {
        background-size: 100% 100%;
        color: $white;
      }
}
  .btn-read-more {
    .button-content {
      border-radius: 26px;
      padding: 2px 12px 3px;
      background: $red;
      color: $white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      width: max-content;
      background-image: linear-gradient($darkRed, $darkRed);
      background-repeat: no-repeat;
      transition: background-size 0s, color 0s;
      background-size: 0% 100%;
      @include media(">=tablet-xl") {
        padding: 6px 24px 8px;
      }
      &:hover {
        background-size: 100% 100%;
        color: $white;
      }
    }
    .icon {
      padding-top: 6px;
      margin-right: 15px;
      transform: rotateY(180deg);
    }
    a {
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
// .project-watch{
//   @include media(">=tablet") {
//     margin-bottom:-60px;
//   }
//    @include media(">=desktop") {
//     margin-bottom:-120px;
//   }
// }
.mode{
&_dark{
  .section_title .title{
     max-width: 100%;
    //  @include media(">=tablet"){
    //    max-width: 240px;
    //  }
   }

  .section_padding {
    padding: 40px 0;
    @include media(">=tablet") {
      padding: 60px 0;
    }
     @include media(">=desktop") {
      padding: 120px 0;
    }
  }
  .project-watch {
    &-head {
      position: relative;
      text-align: center;
      color: $white;
      background: $black;
      margin: 0;
      padding: 105px 0 60px;
       @include media(">=tablet") {
        padding: 160px 0 100px;
      }
      @include media(">=desktop") {
        padding: 180px 0 120px;
      }
      @include media(">=widescreen") {
        padding: 300px 0 240px;
      }
      &__image {
        background: linear-gradient(180deg, $black 50%, $white 50%);
        border: none;
        margin-top: -1px;
        text-align: center;
      }
    }
    &-title {
      text-transform: uppercase;
      font-size: 27px;
      line-height: 40px;
      @include media(">=tablet") {
        font-size: 40px;
        line-height: 80px;
      }
      @include media(">=desktop") {
        font-size: 56px;
      }
      @include media(">=widescreen") {
        font-size: 64px;
      }
      &_sub{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin:20px 0 0;
        padding:0 20px;
        @include media(">=tablet") {
          font-size: 22px;
          line-height: 32px;
        }
        @include media(">=desktop") {
          font-size: 24px;
        }
        @include media(">=widescreen") {
          font-size: 24px;
        }
      }
    }
    &-technology{
      color:$grey;
    }
    &-icons {
      margin: 20px 0;
      img{
      @include media("<tablet-xl") {
        max-height:80px;
      }
      }
    }
    &__column{
      &-float_image{
        float:right;
        margin:0 0 20px 20px;
        img{
          width:150px;
            @include media('>=tablet'){
               width:200px;
            }
             @include media('>=desktop'){
               width:250px;
            }
             @include media('>=widescreen'){
              width:300px;
            }
            @include media('>=widescreen-xl'){
              width:400px;
            }
          }
      }
        &-text{
        .column{
          width:100%;
          margin-right: 0;
          &:first-child{
            @include media(">=widescreen-xl") {
             margin-right: 80px;
            }
          }
        }
        .row{
          display:block;
          @include media(">=widescreen-xl") {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .section_content {
    @include media(">=widescreen-xl"){
       .column{
        p:last-child{
          margin-bottom: 0;
        }
    }
    }
    .column{
      &:last-child{
        p:last-child{
          margin-bottom: 0;
        }
      }
    }
     .without_margin{
      &_bottom{
        margin-bottom:0;
      }
      &_top{
        margin-top:0;
      }
    }
    .red{
      color:$red;
    }
    a{
      color:$red;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    @include media(">=desktop") {
      display: block;
    }
  }
}
&_light{
    .section_title .title{
     max-width: 100%;
     @include media(">=tablet"){
       max-width:240px;
     }
     @include media(">=widescreen"){
       max-width:100%;
     }
   }
    padding-bottom: 40px;
    @include media(">=tablet-xl") {
      padding-bottom: 80px;
    }
    @include media(">=desktop") {
      padding-bottom: 120px;
    }
  .project-watch{
    &-head{
      text-align: center;
      color: $black;
      background: $white;
      margin: 0;
      padding: 80px 0 60px;
       @include media(">=tablet") {
        padding: 100px 0 100px;
      }
      @include media(">=desktop") {
        padding: 120px 0 120px;
      }
      @include media(">=widescreen") {
        padding: 240px 0 240px;
      }
      &__image{
        text-align: center;
      }
      &__text{
        padding-top: 0;
        @include media(">=tablet") {
          padding-top: 60px;
        }
        @include media(">=desktop") {
          padding-top: 120px;
         }
        p
        .column{
          p:last-child{
            margin-bottom: 0;
          }
        }
        a{
          color:$red;
          text-decoration: none;
          &:hover{
            text-decoration: underline;
          }
        }
        .section_padding {
          padding: 0;
        }
        &_icon{
          margin-bottom:20px;
        }
      }
    }
    &-title {
      text-transform: uppercase;
      font-size: 27px;
      line-height: 40px;
      @include media(">=tablet") {
        font-size: 40px;
        line-height: 80px;
      }
      @include media(">=desktop") {
        font-size: 56px;
      }
      @include media(">=widescreen") {
        font-size: 64px;
      }
      &_sub{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin:20px 0 0;
        padding:0 20px;
        @include media(">=tablet") {
          font-size: 22px;
          line-height: 32px;
        }
        @include media(">=desktop") {
          font-size: 24px;
        }
        @include media(">=widescreen") {
          font-size: 24px;
        }
      }
    }
    &-products-group{
      .section_padding {
        padding: 40px 0 0;
       @include media(">=tablet") {
          padding: 60px 0 0;
        }
        @include media(">=desktop") {
          padding: 120px 0 0;
        }
      }
      &__desc{
        color:$grey;
      }
      &__icons{
        display: flex;
        justify-content: right;
         @include media(">=tablet") {
            justify-content: left;
          }
        img{
          max-height:30px;
          margin:0 10px 20px 0;
          @include media(">=desktop") {
            max-height:50px;
            margin:0 30px 20px 0;
          }
          @include media(">=widescreen") {
            max-height:50px;
            margin:0 30px 20px 0;
          }
      }
        &-bottom{
        display: flex;
        justify-content: space-between;
        @include media(">phone") {
          justify-content: left;
        }
        img{
          margin-right: 10px;
           @include media(">phone") {
            height:35px;
            margin-right: 30px;
          }
           @include media(">=tablet") {
            height:30px;
            margin-right: 30px;
          }
          @include media(">=widescreen") {
            height:50px;
            margin-right: 50px;
          }
        }
      }
    }
      &__text{
        .column{
          p:last-child{
            margin-bottom: 0;
          }
        }
        p:last-child{
          margin-bottom: 0;
        }
        a{
          text-decoration: none;
          color:$red;
          &:hover{
            text-decoration:underline;
          }
        }
      }
      &__head-image{
        img{
           margin-top: 20px;
          @include media(">=desktop"){
            margin-top: 40px;
          }
        }
      }
    }
  }

.section_content {
   .row{
        display:block;
        @include media(">=desktop") {
          display: flex;
          justify-content: space-between;
        }
      }
  .column{
    &:last-child{
      p:last-child{
        margin-bottom: 0;
      }
    }
  }
    .without_margin{
      &_bottom{
        margin-bottom:0;
      }
      &_top{
        margin-top:0;
      }
    }
    @include media(">=desktop") {
      display: block;
    }
  }
}
}

.breadcrumbs {
  background: $white;
  width: 100%;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 1;
  transition: all 0.3s ease;
  &__container {
    padding: 0 16px;
    width: 100%;
    max-width: 1920px;
    @include media("<phone") {
      margin: 10px 0;
    }
    @include media(">=tablet") {
      padding: 0 40px;
    }

    @include media(">=widescreen") {
      padding: 0 80px;
      margin: 0 auto;
    }
  }

  &__breadcrumb {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0px;
    transition: all 0.3s ease;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: inherit;
      border: none;
    }
    &::-webkit-scrollbar-track {
      border: none;
    }
    li {
      display: flex;
      min-width: max-content;
    }

    & > li {
      font-size: 12px;

      @include media("<=phone") {
        font-size: 11px;
      }

      @include media(">=tablet") {
        font-size: 16px;
      }
    }

    .link__item:after {
      font-size: 12px;
      padding: 0 10px;
      color: $base-text-color;
      content: "→";

      @include media("<=phone") {
        font-size: 8px;
        padding: 0 3px;
      }

      @include media(">=tablet") {
        font-size: 16px;
      }
    }
    &__item > a {
      color: $base-text-color;
      text-decoration: none;
      font-size: 12px;
      transition: all 0.3s;

      @include media("<=phone") {
        font-size: 11px;
      }

      @include media(">=tablet") {
        font-size: 16px;
      }
    }

    &:hover {
      .breadcrumbs__breadcrumb__item {
        & a {
          opacity: 0.5;

          &:hover {
            opacity: 1;
            color: $red;
          }
        }
      }
    }
  }
}

// .back {
//   color: $white;
//   background: $red;
//   text-decoration: none;
//   font-size: 16px;

//   &:hover {
//     color: $base-text-color;
//     background: $white;
//   }
// }

.buttons {
    @include media(">=tablet") {
    //  margin-top: 60px;
    //  margin-bottom:-60px;
  }
   @include media(">=desktop") {
    //  margin-top: 120px;
    //  margin-bottom:-120px;
  }
}
.button-group{
  .primary{
     text-decoration: none;
     font-size: 16px;
  }
   @include media('>=tablet'){
    display: flex;
    justify-content: space-between;
    .group{
       width: 65%;
    }
   }
  .group{
    display:flex;
    justify-content:space-between ;
  }
}
.button-alone{
  @include media('>=tablet'){
    display: flex;
    justify-content: space-between;
    .alone{
       width: 65%;
    }
  }
}
.first_list{
  margin-top:0;
}
</style>
