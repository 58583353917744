<template>
  <div class="contacts-page">
    <section class="contacts-home">
      <div class="container">
        <h1 class="contacts-title">Contact us</h1>
      </div>
    </section>

    <div class="container row contacts-content">
      <div class="column">
        <h2 class="contacts-content-title">We would love to hear from you</h2>
        <p>
          Please use the form to contact us for a quote or simply just get in
          touch. You can also reach us via phone at any of our international
          offices or via our main email address: <a href="mailto:wdevs@wdevs.com">wdevs@wdevs.com</a>
        </p>
        <div class="row contacts-content-address">
          <div class="column">
            <h3 class="contacts-content-address-title">USA</h3>
            <p>3 floor at 1007 Chestnut St, Newton, MA 02464 USA</p>

            <div>
              <p class="contact-phone">
                <span><a href="tel:+16143549478" class="phone-link">+1 614 354 9478</a></span>
                <br/>
                <span class="desc-phone">
                  (Sales department)
                </span>
              </p>
              <p class="contact-phone">
              <span><a href="tel:+16174184077" class="phone-link">+1 617 418 4077</a></span><br/>
              <span class="desc-phone">
                (General issues)
              </span>
              </p>
            </div>
          </div>

          <div class="column center">
            <h3 class="contacts-content-address-title">EU</h3>
            <p>
              WEBDEVS LTD<br/>
              Ifigeneias 14, 3036, Limassol, Cyprus<br/>
            </p>
            <p>
              <span>Tel: <a href="tel:+351962339478" class="phone-link">+3 519 623 39478</a></span
              ><br />
            </p>
          </div>
        </div>
      </div>
      <div class="column contacts-form">
        <GetInTouchForm class="center"  @onFormSubmission="onFormSubmission">
           <div class="contacts-contact-form-header"  v-if="submission.isSent" :class="submission.isSent?'modal':''">
            <button
            class="modal-close"
            @click="close()"
            aria-label="Close"
          >
            <img
              v-svg-inline
              :data-src="require('@/assets/icons/close-modal.svg')"
              alt=""
            />
          </button>
      <div class="contacts-contact-form-description"
        :class="{
          error: submission.hasError,
          success: !submission.hasError
        }">
          {{ submission.messageText.split('! ')[0]}}!<br/>
          {{ submission.messageText.split('! ')[1]}}
       </div>
    </div>
        </GetInTouchForm>
      </div>
    </div>
  </div>
</template>
<script>
import GetInTouchForm from "../components/Form/GetInTouchForm.vue";

export default {
  data(){
    return{
      submission: this.getDefaultSubmission()
    }
  },
  components: {
    GetInTouchForm,
  },
  methods:{
    close(){
      this.submission.isSent = false
    },
     getDefaultSubmission() {
      return {
        isSent: false,
        hasError: false,
        messageText: ''
      }
    },
    onFormSubmission(payload) {
      Object.assign(this.submission, this.getDefaultSubmission(), payload);
    },

  }
};
</script>
<style lang="scss">
@import "../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.contacts {
  &-home {
    padding: 60px 0;
    @include media(">=desktop") {
      padding: 80px 0;
    }
  }
  &-title {
    text-transform: uppercase;
    color: $black;
    line-height: 111%;
    margin: 0;
    font-size: 34px;
    @include media(">=tablet") {
      font-size: 50px;
    }
    @include media(">=tablet-xl") {
      font-size: 60px;
    }
    @include media(">=desktop") {
      font-size: 64px;
    }
  }
  &-content {
    display: block;
    @include media(">=tablet-xl") {
      display: flex;
    }
    &-title {
      font-size: 24px;
    }

    .column {
      &:first-child {
        margin-right: 30px;
        @include media(">=desktop") {
          margin-right: 0;
        }
      }
    }
    &-address {
      display: block;
      &-title {
        margin: 30px 0 10px;
        font-size: 21px;
      }

      p {
        margin-bottom: 10px;
      }
      @include media(">=desktop") {
        display: flex;
        p {
          margin-bottom: 20px;
        }
      }
    }
  }
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.column {
  .form {
    margin: 0 auto;
  }
  .contact-form {
    max-width: 658px;
    max-height: 1000px;
    margin-top: 60px;
    @include media(">=tablet-xl") {
      margin-top: 0;
      max-width: 478px;
    }
    form.form{
      max-height: 1000px;
    }
  }
}
.center {
  @include media(">=tablet") {
    margin: 0 auto;
  }
}
.desc-phone{
  font-size: 16px;
}
.contact-phone{
  line-height: 18px;
}
</style>
