<template>
  <div class="razzo-project" @scroll="scrolling()">
    <breadcrumbs
        :breadcrumbs="breadcrumbs"
        :scroll="scroll"
        @goBack="back"
    />
    <div class="razzo-project-head-container">
      <div class="razzo-project-head">
        <h1 class="razzo-project-head__title">
          Scale up <br />your eCommerce business with Razzo
        </h1>
        <p class="razzo-project-head__desc">
          With this powerful & easy-to-use
          <a href="https://razzo.io?via=wdevs" class="head_link_razzo" target="_blank"
            >Razzo.io</a
          >
          tool, you can be sure your online marketing delivers the most accurate
          data in simple and informative graphics. Special features help you
          analyze your clients’ needs & customer behaviour for any period of
          time you choose. Perfect choice for Magento-based website owners!
        </p>
        <button
          class="button primary large-razzo"
          @click="$router.push('/blog#info')"
        >
          Learn More
        </button>
        <div class="button light large-razzo">
          <a href="https://razzo.io?via=wdevs" target="_blank">Visit Razzo </a>
        </div>
      </div>
    </div>

    <div class="razzo-project-image">
      <agile
        ref="carousel"
        class="razzo-project-image__carausel"
        :nav-buttons="true"
        :dots="false"
        :autoplay-speed="5000"
        :speed="2500"
        :initial-slide="1"
        :options="myOptions"
        autoplay
      >
        <div>
          <img src="../../assets/razzo/razzo-head-page-1.png" alt="Total Revenue dashboard" title="Total Revenue" />
        </div>
        <div>
          <img src="../../assets/razzo/razzo-head-page-4.png" alt="Sold Products and Abandonded Cart dashboard" title="Sold Products" />
        </div>
        <div>
          <img src="../../assets/razzo/razzo-head-page.png"  alt="New vs Returning Customers dashboard" title="Customers"/>
        </div>
        <div>
          <img src="../../assets/razzo/razzo-head-page-3.png" alt="Customers Info dashboard" title="Customers Info" />
        </div>
        <div>
          <img src="../../assets/razzo/razzo-head-page-2.png" alt="Product Info dashboard" title="Product Info" />
        </div>
      </agile>
    </div>
    <div
      class="razzo-project-section_icons row razzo-container container"
      id="info"
    >
      <div
        class="razzo-project-section_icons__icon column"
        v-for="section in icons_section"
        :key="section.id"
      >
        <img
          class="razzo-project-section_icons__image"
          v-svg-inline
          :data-src="require(`@/assets/razzo/icons/${section.src}`)"
          alt=""
        />
        <h2 class="razzo-project-section_icons__title">{{ section.title }}</h2>
        <p class="razzo-project-section_icons__text">{{ section.text }}</p>
      </div>
    </div>
    <div class="razzo-project-section_description">
      <div
        class="row razzo-container container"
        v-for="desc in descriptions"
        :key="desc.id"
      >
        <div class="column">
          <h2 class="razzo-project-section_description__title">
            {{ desc.title }}
          </h2>
          <div
            class="razzo-project-section_description__text"
            v-html="desc.text"
          ></div>
        </div>
        <div class="column">
          <img
            v-webp:src="require(`@/assets/razzo/${desc.src}.webp`)"
            :src="require(`@/assets/razzo/${desc.src}.png`)"
            :alt="desc.alt"
            :title="desc.img_title"
            loading="lazy"
          />
        </div>
      </div>
    </div>
    <ProjectsRazzoClientsCases />
    <ProjectsRazzoFAQ />
    <ProjectRazzoContactForm id="razzo-contact" />
      <div class="container blog-razzo section_holder">
      <div class="section_title">
        <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath" />
      </div>
      <div class="section_content">
           <div
    class="btn-read-more primary contacts-button small"
     @click="this.$router.push('/blog')"
  >
    <span class="button-content">
    <span class="icon"
        ><img
          v-svg-inline
          :data-src="require('@/assets/icons/rarr-icon.svg')"
          alt=""
      /></span>
     Read more our stories
      </span>
  </div>
  </div>
  </div>
  </div>
</template>
<script>
import SidebarBlog from "../Blog/Sidebar.vue"
import ProjectsRazzoClientsCases from "./ProjectRazzoClientsCases.vue";
import ProjectsRazzoFAQ from "./ProjectRazzoFAQ.vue";
import ProjectRazzoContactForm from "./ProjectRazzoContactForm.vue";
import { VueAgile } from "vue-agile";
import { mapState } from "vuex";
import Breadcrumbs from "/src/components/Breadcrumbs";

export default {
  name: "ProjectRazzo",
  agile: VueAgile,
  components: {
    ProjectsRazzoClientsCases,
    ProjectsRazzoFAQ,
    ProjectRazzoContactForm,
    SidebarBlog,
    Breadcrumbs,
  },
  data() {
    return {
      scroll:false,
      backHistory:history.state.back,
      tags:['Ecommerce Dashboard'],
      myOptions: {
        responsive: [
          {
            breakpoint: 300,
            settings: {
              slidesToShow: 1,
            },
          },

          {
            breakpoint: 2500,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
      icons_section: [
        {
          id: 1,
          src: "allinone.svg",
          title: "All in one",
          text: " Keep all your marketing data in one place. No need to install multiple modules to create reports & visualization.",
        },
        {
          id: 2,
          src: "fullinsight.svg",
          title: "Get full insight",
          text: "Razzo tool gives you an instant overview of all key metrics for your Magento shop. Forget about 3rd party reports and outsource specialists.",
        },
        {
          id: 3,
          src: "kpis.svg",
          title: "Improve KPIs",
          text: "Get data widgets here-and-now showing how your business is doing and has been doing in the past.",
        },
      ],
      descriptions: [
        {
          id: 1,
          src: "razzo1",
          alt: "Project Progress dashboard",
          img_title:"Progress",
          title: "User-friendly interface",
          text: `<p>Simple & clear architecture gives you the opportunity to monitor all essential marketing & sales information. User’s dashboard features data widgets displaying all you need to know about your sales and customers – revenue (by week, hour, or total revenue), total orders, popular payment & shipping methods, average basket value and even lost revenue to analyze & improve your sales funnel.</p>`,
        },
        {
          id: 2,
          src: "razzo2",
          alt: "Project  dashboard overview",
          img_title:"Dashboard",
          title: "Custom widgets",
          text: `<p>Avoid spending extra time on searching and visualizing your data. Our custom widgets provide full overview of how your business is running. For example, exclusive tool for estimating your potential revenue by visualizing abandoned carts for a particular period. Explore your customers’ behavior & further arrange your email campaigns. Or another widget which allows you to compare the number of new & returning customers. Turn your website visitors into regular buyers & improve your LTV rate.</p>`,
        },
        {
          id: 3,
          src: "razzo3",
          alt: "Customers Info  dashboard overview",
          img_title:"Customers Overview",
          title: "Customer Data Platform",
          text: `<p>Positive customer experience is vital for your eCommerce business success because satisfied customers turn to your loyal clients who can boost your long-term revenue & improve LTV ratio. Get a detailed profile of your real customer – compare the number of new and returning visitors, look deeper into average order value information. Razzo allows to automatically store clients’ contacts & order history. The more you know about your customers, the easier it can be to increase their spending.</p>`,
        },
        {
          id: 4,
          src: "razzo4",
          alt: "Registration form sample",
          img_title:"Project registration form",
          title: "Easy integration with Magento eCommerce websites",
          text: `<p>Razzo connects with the Magento platform using its built-in API. This means, that you or your marketing crew does not need to install or add anything to your website.</p>`,
        },
        {
          id: 5,
          src: "razzo5",
          alt: "Subscription plans overview",
          img_title:"Subscription Plans",
          title: "Easy integration with Magento eCommerce websites",
          text: `<p>Choose your tariff plan according to your goals & needs. Cancel whenever you want. The 30-day trial is free. The prices start from only $29 USD per month.</p>
                  <p>
                  Need more than standard options? Feel free to contact us anytime!
                  </p>`,
        },
      ],
      images: [
        "razzo-head-page",
        "razzo-head-page-1",
        "razzo-head-page-2",
        "razzo-head-page-3",
        "razzo-head-page-4",
      ],
      breadcrumbs: [
        {
          link: '/',
          name: 'Home',
        },
        {
          name: 'Blog',
        },
        {
          name: 'Razzo',
        },
      ],
    };
  },
  methods:{
    goToPath(tag){
      let post = this.blog.find(item => item.tags.includes(tag))
      this.$router.push(post.img_src)
    },
    back(){
    if(!document.referrer || this.backHistory !== '/blog'){
        Object.defineProperty(document, "referrer", {
          get: function () {
            return `${window.location.origin}/blog`;
          },
        });
        this.$router.push('/blog')
        return
      } else{
        this.$router.go(-1)
      }
    },
    scrolling() {
      const scroll = document.documentElement.scrollTop;
      if (scroll > 50) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    }
  },
   computed:{
    ...mapState(['blog'])
  },
  created() {
    window.addEventListener("scroll", this.scrolling);
    // history.pushState('razzo',window.location.href)
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>
<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.blog-razzo{
  .sidebar:last-child{
    margin-bottom: 0;
  }
   &.section_holder {
    padding-top:120px; 
    display: flex;
    flex-direction: column-reverse;
    @include media(">=tablet-xl") {
      flex-direction: column-reverse;
    }
  }
  .section_title {
    margin-right: 0px;
    width: 100%;
    @include media(">=tablet-xl") {
      margin-right: 43px;
      width: 100%;
      margin-bottom: 0;
    }
  }
  .section_content {
    margin-bottom: 60px;
    display: block;
    margin-left: 0px;
    width: 100%;
  }
}

.head_link_razzo{
  color:$white;
  text-decoration:none;
  border-bottom:1px solid $white;
  &:hover{
    text-decoration:none!important;
  }

}

.agile {
  &__nav-button {
    background: transparent;
    color: transparent;
    width: 160px;
    height: 100%;
    position: absolute;
    top: 0;
    @include media(">=widescreen") {
      width: 400px;
      top: 0;
    }
    &--next {
      @include media(">=widescreen") {
        right: 0;
      }
    }
    &--prev {
      @include media(">=widescreen") {
        left: 0;
      }
    }
  }
}
.razzo-container {
  @include media(">=tablet-xl") {
    max-width: 1100px;
  }
  @include media(">=widescreen") {
    max-width: 1454px;
  }
}
.razzo-project {
  overflow-x: hidden;
  .go-back-to-read {
    padding: 16px 10px;
    border-bottom-left-radius: 26px 26px;
    border-bottom-right-radius: 26px 26px;
    position: fixed;
    background: $white;
    z-index: 50;
    width: 100vw;
    transition: all 0.3s;
    @include media(">=tablet-xl") {
      padding: 16px 80px;
    }
    &.scrolling {
      padding: 5px 10px;
      border-bottom: 1px solid $red;
       @include media(">=tablet-xl") {
         padding: 5px 80px;
       }

    }
  }
  .button-content {
    border-radius: 26px;
    padding: 2px 12px 3px;
    background: $red;
    color: $white;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
    width: max-content;
    background-image: linear-gradient($darkRed, $darkRed);
    background-repeat: no-repeat;
    transition: background-size 0s, color 0s;
    background-size: 0% 100%;
    @include media(">=tablet-xl") {
      padding: 6px 24px 8px;
    }
    &:hover {
      background-size: 100% 100%;
      color: $white;
    }
    .icon{
      padding-top:6px;
      margin-right: 15px;
      transform: rotateY(180deg);
    }
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  }
  .button.light {
    a {
      color: $black;
      &:hover {
        text-decoration: none;
      }
    }
    position: relative;
    display: inline-block;
    color: $black;
    border: none;
    margin-left: 20px;
    background-image: linear-gradient($darkenRed, $darkenRed);
    background-repeat: no-repeat;
    transition: background-size 0.3s, color 0.3s;
    background-size: 0% 100%;
    &:hover {
      background-size: 100% 100%;
      color: $white;
      a {
        color: $white;
      }
    }
  }

  .razzo-project-head .large-razzo {
    border: none;
    @include media("<phone") {
      border: none;
      font-size: 14px;
      font-weight: 600;
      padding: 6px 24px;
    }
  }
  &-head-container {
    padding: 120px 0 100px;
    color: $white;
    background: $violet;
    @include media(">=tablet") {
      padding: 160px 0 240px;
    }
  }
  &-head {
    h1 {
      margin: 0;
    }
    max-width: 1147px;
    margin: 0 auto;
    text-align: center;
    .large-razzo {
      margin-top: 0px;
      padding: 10px 47.5px;
      border-radius: 26px;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
    }

    &__title {
      font-weight: 700;
      font-size: 35px;
      line-height: 40px;
      text-transform: uppercase;
      padding-bottom: 20px;
      @include media(">=tablet") {
        font-size: 50px;
        line-height: 60px;
        padding-bottom: 40px;
      }
      @include media(">=tablet-xl") {
        font-size: 45px;
        line-height: 55px;
      }
      @include media(">=desktop") {
        font-size: 60px;
        line-height: 68px;
      }
      @include media(">=widescreen") {
        font-size: 64px;
        line-height: 80px;
      }
    }
    &__desc {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      padding: 0 20px 0px;
      margin-bottom: 30px;
      @include media(">=widescreen") {
        padding: 0 0 0 40px;
        margin-bottom: 40px;
      }
    }
  }
  &-image {
    text-align: center;
    background: transparent;
    &__carausel {
      margin: -60px auto 0;
      @include media(">=tablet") {
        margin: -160px auto 0;
      }
    }
  }
  &-section_icons {
    padding: 50px 0 60px;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    @include media(">=tablet") {
      padding: 100px 0px 92px;
    }
    @include media(">=tablet-xl") {
      justify-content: space-between;
    }
    @include media(">=widescreen") {
      padding: 160px 0 250px;
    }
    .column {
      margin: 0 auto 60px;
      @include media(">=tablet-xl") {
        margin: 0;
      }
    }
    &__image {
      margin-bottom: -5px;
    }
    &__text {
      line-height: 30px;
      padding-bottom: 28px;
      @include media(">=tablet-xl") {
        padding-bottom: 0px;
      }
    }
    &__icon {
      margin: 0 auto 40px;
      width: 310px;
      &:last-child {
        margin-bottom: 0px;
      }
      svg {
        width: 120px;
        height: 120px;
        @include media(">=widescreen") {
          width: 160px;
          height: 160px;
        }
      }
      @include media(">=widescreen") {
        width: 380px;
      }
      .razzo-project-section_icons__text:last-child {
        margin-bottom: 0;
      }
    }
    &__title {
      font-size: 30px;
      margin-bottom: 20px;
      &:before {
        content: "";
        display: block;
        width: 60px;
        height: 5px;
        background: $red;
        margin: 20px auto 10px;
        @include media(">=widescreen") {
          margin: 40px auto 20px;
        }
      }
      @include media(">=widescreen") {
        font-size: 40px;
      }
    }
  }
  &-section_description {
    padding: 80px 0 0;
    @include media(">=tablet") {
      padding: 120px 0 80px;
    }
    @include media(">=desktop") {
      padding: 0;
    }
    .row {
      display: block;
      padding-bottom: 40px;
      .column {
        width: 100%;
        &:last-child {
          text-align: center;
        }
      }
      @include media(">=desktop") {
        display: flex;
        padding-bottom: 0;
        align-items: center;
        padding: 100px 0 40px;
        justify-content: space-between;
        .column:first-child {
          align-self: center;
          flex-grow: 1;
          max-width: 60%;
          margin-right: 0;
        }
        .column:last-child {
          flex-grow: 1;
          margin-right: -30px;
          padding-left: 19px;
          img {
            max-width: 600px;
          }
        }
        &:nth-child(even) {
          flex-direction: row-reverse;
          .column:first-child {
            margin-left: 60px;
          }
          .column:last-child {
            margin-left: -30px;
            padding: 0;
          }
        }
      }
      @include media(">=widescreen") {
        .column:first-child {
          max-width: 37%;
        }
        .column:last-child {
          img {
            max-width: 940px;
          }
          margin-right: -65px;
          padding-left: 0;
        }
        &:nth-child(even) {
          flex-direction: row-reverse;
          .column:first-child {
            margin-left: 65px;
          }
          .column:last-child {
            margin-left: -65px;
          }
        }
      }
    }
    background: $violet;
    color: $white;

    &__title {
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      &:before {
        content: "";
        display: block;
        width: 60px;
        height: 5px;
        background: $red;
        margin-bottom: 20px;
      }
    }
    &__text {
      font-size: 20px;
      line-height: 32px;
    }
  }
}
.razzo-tags{
  padding:20px;
  margin:40px 0;
  color:$red;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  @include media (">=tablet"){
    margin:80px 0 0;
    padding:40px;
    text-align: center;
  }
  span{
    margin-right: 20px;
    @include media (">=tablet"){
      margin-right: 40px;
    }
  }
}
</style>
