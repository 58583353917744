<template>
    <div class="blog">
        <section class="blog-home">
            <div class="container">
                <h1 class="blog-title">Blog</h1>
            </div>
        </section>
        <div class="container section_holder">
            <div class="section_title">
                <SidebarBlog @searchByTag="searchByTag" @searchByTags="searchByTags"/>
            </div>
            <div class="section_content">
                <BlogContent :blog="getItems" :tagFromPost='[]'/>
                <!--v-if="getItems > perPage"-->
                <div class="section_pagination">
                    <paginate
                        v-if="getPaginateCount > 1"
                        :page-count="getPaginateCount"
                        :page-range="5"
                        :margin-pages="1"
                        :initial-page="currentPage"
                        :click-handler="paginateClickCallback"
                        :prev-text="''"
                        :next-text="''"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        class="blog_paginate"
                    >
                    </paginate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarBlog from '../components/Blog/Sidebar.vue';
import BlogContent from '../components/Blog/BlogContent.vue';
import {mapState} from 'vuex'
import Paginate from 'vuejs-paginate-next';

export default {
    components: {
        SidebarBlog,
        BlogContent,
        Paginate
    },
    data() {
        return {
            currentPage: 1,
            perPage: 5,
            content: '',
        }
    },
    computed: {
        ...mapState(['blog']),
        getItems: function () {
            let start = (this.currentPage - 1) * this.perPage;
            let end = this.currentPage * this.perPage;
            return this.content.slice(start, end);
        },
        getPaginateCount: function () {
            return Math.ceil(this.content.length / this.perPage);
        },
    },
    methods: {
        paginateClickCallback: function (pageNum) {
            document.documentElement.scrollTop = 200
            this.currentPage = Number(pageNum);
            sessionStorage.currentPage = this.currentPage
        },
        searchByTag(tag) {
            if (tag.length) {
                this.content = this.blog.filter(item => item.tags.find(t => t === tag))
            } else {
                this.content = this.blog
            }
        },
        searchByTags(tags) {
            if (tags.length) {
                this.content = this.blog.filter(item => item.tags.some(i => tags.includes(i)))
            } else {
                this.content = this.blog
            }

        }
    },
    watch: {
        content(newVal) {
            this.content = newVal
        }
    },
    created() {
        this.content = this.blog
        if (sessionStorage.currentPage && history.state.forward !== null) {
            this.currentPage = +sessionStorage.currentPage
        } else {
            sessionStorage.currentPage = 1
            this.currentPage = 1
        }

    }
}
</script>

<style lang="scss">
@import "../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.blog {
    .section {
        &_holder {
            display: flex;
            flex-direction: column-reverse;
            @include media(">=tablet-xl") {
                flex-direction: row;
            }
        }
        &_title {
            width: 100%;
            margin-right: 60px;
            margin-bottom: 0;
            padding-bottom: 0;
            @include media(">=tablet-xl") {
                width: 35%;
                margin-right: 43px;
            }
        }
        &_content {
            display: block;
            width: 100%;
            margin-bottom: 60px;
            margin-left: 0;
            @include media(">=tablet-xl") {
                width: 65%;
                margin-bottom: 0;
                margin-left: 37px;
            }
        }
        &_pagination {
            padding-top: 40px;
        }
    }

    &-home {
        padding: 60px 0;
        @include media(">=desktop") {
            padding: 80px 0;
        }
    }

    &-title {
        margin: 0;
        font-size: 34px;
        line-height: 111%;
        text-transform: uppercase;
        color: $black;
        text-align: center;
        @include media(">=tablet") {
            font-size: 50px;
        }
        @include media(">=tablet-xl") {
            font-size: 60px;
        }
        @include media(">=desktop") {
            font-size: 64px;
        }
    }
}

.row {
    display: flex;
    .col:first-child {
        margin-right: 80px;
    }
}

.blog_paginate {
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style-type: none;
    color: $red;
    @include media(">=tablet-xl") {
        justify-content: flex-start;
    }
    .page {
        &-item {
            &:first-child,
            &:last-child {
                display: none;
            }
            &.active {
                .page-link {
                    color: $white;
                    background-color: $red;
                }
            }
        }
        &-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            margin-right: 10px;
            border: 2px solid $red;
            border-radius: 50%;
            padding: 5px 10px;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: $red;
            cursor: pointer;
            transition: 0.25s color ease-in-out, 0.25s background-color ease-in-out;
            @include media(">=tablet-xl") {
                width: 50px;
                height: 50px;
                margin-right: 20px;
                padding: 14px 20px;
            }
            &:hover {
                color: $white;
                background-color: $red;
            }
        }
    }
}
</style>
