<template>
<ReferencesSliderSection dark='true' magento='true' :feedbacks="magento_feedbacks" title="References"/>
</template>

<script>
import ReferencesSliderSection from "../ReferencesSliderSection.vue";
import { mapState } from "vuex";

export default {
 components:{
    ReferencesSliderSection
  },
  computed: {
    ...mapState(["magento_feedbacks"]),
  },
};
</script>
