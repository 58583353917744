<template>
  <div class="contact-form razzo-contact" @submit.prevent="submitForm">
    <form
        class="form"
        @submit.prevent="submitForm"
    >
      <div class="input-group">
        <input
            name="name"
            id="name"
            v-model.trim="name"
            type="text"
            autocomplete="on"
            placeholder=" "
            class="input-group__input"
            @change="v$.name.$validate()"
            :class="[{ error: v$.name.$error }, { success: name.length }]"
        />
        <label
            class="placeholder"
            :class="{ error: v$.name.$error }"
            for="name"
        >
          Your Name<span>*</span>
        </label>
      </div>
      <span
          v-if="v$.name.$error"
          class="error"
      >
        Error: name is required
      </span>
      <div class="input-group">
        <input
            name="email"
            id="email"
            v-model.trim="email"
            placeholder=" "
            type="email"
            autocomplete="on"
            class="input-group__input"
            @change="v$.email.$validate()"
            :class="[{ error: v$.email.$error }, { success: email.length }]"
        />
        <label
            class="placeholder"
            :class="{ error: v$.email.$error }"
            for="email"
        >
          Email Address<span>*</span>
        </label>
      </div>
      <span
          v-if="v$.email.$error"
          class="error"
      >
        Error: email entered incorrectly
      </span>
      <div class="input-group">
        <input
            name="phone"
            id="phone"
            v-model.trim="phone"
            placeholder=" "
            type="tel"
            autocomplete="on"
            class="input-group__input"
            @change="v$.phone.$validate()"
            :class="[{ error: v$.phone.$error}, { success:!isNaN(phone)&&phone!==''}]"
        />
        <label
            class="placeholder"
            :class="{ error: v$.phone.$error}"
            for="phone"
        >
          Phone<span>*</span>
        </label>
      </div>
      <span
          v-if="v$.phone.$error"
          class="error"
      >
        Error: phone number should contain at least 10 digits
      </span>

      <div class="input-group input-group__textarea">
        <textarea
            name="message"
            id="message"
            placeholder=" "
            v-model.trim="message"
            @change="v$.message.$validate()"
            :class="[{ error: v$.message.$error }, { success: message.length }]"
        />
        <label
            class="placeholder"
            :class="{ error: v$.message.$error }"
            for="message"
        >
          Your Question<span>*</span>
        </label>
      </div>
      <span
          v-if="v$.message.$error"
          class="error"
      >
        Error: message is required
      </span>
      <button
          class="button primary large send"
          type="submit"
          :class="[isFormSubmissionInProgress?'loading':'']"
          :disabled="isFormSubmissionInProgress"
      >
        {{ isFormSubmissionInProgress ? 'Sending' : 'Send' }}
        <span
            v-if="!isFormSubmissionInProgress"
            class="icon"
        >
          <img
              v-svg-inline
              :data-src="require('../../assets/icons/rarr-icon.svg')"
              alt=""
          />
        </span>
      </button>
    </form>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, email,minLength,helpers} from "@vuelidate/validators";
import axios from "axios";

export default {
  name: "FormContact",
  data() {
    return {
      v$: useValidate(),
      name: "",
      email: "",
      phone:"",
      message: "",
      isFormSubmissionInProgress:false,
    };
  },
  props:['description'],
  validations() {
    const num = helpers.regex(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/);
    return {
      name: { required },
      email: { required, email },
      phone:{ required,
              num,
              minLength:minLength(10)
            },
      message: { required }
    };
  },
  computed:{
    phoneNumber(){
      return this.phone.replace(/[^+\d]/g, '')
  }},
  methods: {
    submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const messageFromUser = {
          subject:'Razzo',
          text:this.description,
          name: this.name,
          email: this.email,
          phone:this.phoneNumber,
          message: this.message,
        };
        // console.log(messageFromUser);
         this.isFormSubmissionInProgress = true;
        axios.post("/backend/contact.php", messageFromUser, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((resp) => {
          // handle success
          const isSuccess = resp?.data?.success || false;
          this.$emit("onFormSubmission", {
            isSent: true,
            hasError: !isSuccess,
            messageText: resp?.data?.message || 'Your request was successfully submitted.'
          });
        })
        .catch((err) => {
          // handle error
          this.$emit("onFormSubmission", {
            isSent: true,
            hasError: true,
            messageText: err?.response?.messageText || 'An internal error has occurred. Please try again later.'
          })
          .then(() => {
            // always executed
            this.isFormSubmissionInProgress = false;
          });
        });
      }
      return;
    }
    
}}
</script>

<style lang="scss">
@import "./form-styles.scss";
</style>
