<template>
    <div class="article-blog-page" @scroll="scrolling()">
        <breadcrumbs
            :breadcrumbs="breadcrumbs"
            :scroll="scroll"
            @goBack="back"
        />
        <section class="article-blog__banner article-blog__banner--eclipse">
            <img
                class="article-blog__banner-img"
                src="@/assets/blog/hopways.jpg"
                alt="Hopways - simplifying school journeys for everyone"
                loading="lazy"
                modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
            <h1 class="article-blog__banner-title">
                HOPWAYS.COM: SIMPLIFYING SCHOOL JOURNEYS FOR EVERYONE
            </h1>
        </section>
        <div class="container article-blog section_holder">
            <div class="section_title">
                <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
            </div>
            <div class="section_content">
                <div class="section_content__item">
                    <p>Hopways is a new service designed to take the stress out of school commutes, offering parents a reliable subscription-based solution for getting their children to school. The idea behind Hopways is straightforward: parents, especially those with busy schedules or a bit more flexibility in their budget, can set up consistent, dependable transportation for their kids, whether they need it every day or just on certain days of the week.</p>
                    <p>What sets Hopways apart is its high standard for selecting drivers. The company goes beyond basic background checks, implementing a multi-step process that carefully screens each driver for safety and experience. The process starts with a detailed application, where drivers provide information about their driving history and qualifications. They also need to submit specific documents (find an example <a href="https://www.hopways.com/drivers/" target="_blank">here</a>) that validate their experience, licensing, and adherence to local safety regulations.</p>
                    <p>After this, drivers are interviewed by Hopways to assess not just their skills, but their understanding of the unique needs involved in transporting children. Once they pass the interview, they undergo a thorough background check, which adds an extra layer of confidence for parents. This process ensures that every driver meets the high safety and professionalism standards Hopways has set.</p>
                    <p>For parents, Hopways offers the benefit of peace of mind, knowing that their children are in safe hands with experienced, well-vetted drivers. By providing a reliable, flexible transport option, Hopways makes it easier for parents to manage their schedules, while giving them confidence that their children’s school commutes are safe and reliable.</p>
                    <h2 class="section_content__item-title">
                        KEEPING HOPWAYS AHEAD OF THE GAME
                    </h2>
                    <p>We decided to build a <a href="https://www.hopways.com/" target="_blank">new website</a> for Hopways, using WordPress as our primary platform. WordPress proved to be an excellent choice for this project, thanks to its flexibility and the wide range of themes and plugins it offers. This adaptability allowed us to design a website that meets the specific needs of Hopways and provides a smooth experience for its users.</p>
                    <p>Understanding that many visitors would access the site from different devices, we focused on creating a responsive design that works well on desktops, tablets, and mobile devices. This approach ensures that all users can easily navigate the website and find the information they need, no matter how they choose to connect. We carefully crafted the layout and structure to provide a seamless user journey, allowing customers to engage with Hopways’ content effortlessly.</p>
                    <p>One of the key advantages of using WordPress for this project was its strong plugin ecosystem. This feature enabled us to quickly add important functionalities to the website without slowing it down. By selecting and configuring the right plugins, we could enhance the site's performance and minimize loading times, which is crucial for keeping visitors engaged. As Hopways continues to grow, they can easily add new features and content to the site without worrying about performance issues. </p>

                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/hopways/hopways-02.jpg"
                            alt="Article image 1"
                            title="Article image 1"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        INNOVATING FOR A BETTER USER EXPERIENCE
                    </h2>
                    <p>For this project, we set out to completely redesign their website with a fresh perspective. The goal was to create a more user-friendly experience, especially for two key groups: parents and drivers. The previous site had its strengths, but we wanted to make it easier for visitors to find the information they needed and feel confident in their choice of service.</p>
                    <p>To achieve this, our WDEVS team focused on simplifying the website's structure. By reorganizing the content and streamlining navigation, we ensured that essential information was easy to access. We also strategically placed call-to-action buttons where users are most likely to look. This thoughtful arrangement helped guide visitors smoothly through the site, leading to a noticeable increase in conversions.</p>
                    <p>Understanding the importance of communication, we included clear contact details for the Hopways team. This feature allows users to reach out easily, ensuring their questions are answered quickly and building a sense of trust.</p>
                    <p>While the new site appears easy-to-use, a lot of careful planning went into its design. A big team of analysts and marketers conducted in-depth research to understand what users truly need. By focusing on these insights, we tailored each element of the site to enhance the user experience without making it overly complicated.</p>
                    <p>We created user-friendly online portals for parents and drivers using Laravel, Vue 3, and Inertia.js. This approach helped us deliver a smooth experience for each group. The project is hosted on Amazon Web Services (AWS), providing a reliable backbone, and we manage email communications using the Microsoft Graph API to ensure effective and easy interactions.</p>
                    <p>A significant aspect of this project was integrating with essential services like Way-Plan, Pennylane, and Hubspot. These integrations helped streamline operations, allowing both parents and drivers to access the information they need quickly and efficiently.</p>
                    <p>For parents, the portal is designed to make their lives easier. It features a clear view of their child’s transportation schedule, including weekly pick-up and drop-off times, addresses, and driver details. This information is all laid out in a way that’s easy to understand, reducing any potential confusion and building trust in the service. Parents can also export this schedule as a PDF, making it convenient to share or keep track of their child’s rides.</p>
                    <p>The portal also allows parents to look back at past trips, cancel rides when necessary, and view invoices right within the platform. This level of transparency helps parents manage their expenses and keeps them informed about their child’s travel history. One of the standout features is the real-time map, which lets parents track their child’s ride live. </p>
                    <p>In addition, we integrated a support ticket system through Hubspot. This setup makes it easy for parents to ask questions or report issues, ensuring they receive timely responses. Parents can also request additional rides beyond their subscription, giving them the flexibility to adapt to changing needs.</p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/hopways/hopways-03.jpg"
                            alt="Article image 2"
                            title="Article image 2"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        HELPING HOPWAYS EXPAND THEIR SERVICES
                    </h2>
                    <p>WDEVS <a href="https://app.hopways.com/" target="_blank">created</a> a dedicated portal for drivers that significantly improves their experience and efficiency on the job. This user-friendly platform enables drivers to easily view their schedules, including pick-up and drop-off locations and contact details for parents. With this access to information, drivers can better manage their daily tasks and stay organized for each trip.</p>
                    <p>One notable feature of the portal is the option for drivers to opt out of trips if needed. This flexibility is important in the transportation sector, as it allows drivers to balance their personal lives while still ensuring that rides are available when necessary. Additionally, drivers can request extra fees to cover tolls or other expenses they may incur during their journeys. This feature promotes fairness in compensation and helps drivers feel valued for their efforts.</p>
                    <p>The portal also includes a payments monitoring section, allowing drivers to track their earnings in real time. This transparency helps them manage their finances more effectively, giving them a clearer picture of their income and enabling better budget planning.</p>
                    <p>Both parents and drivers have their profiles within the portal. Parents can add important information about their children, including any special needs or preferences. This feature allows drivers to provide personalized service, ensuring a comfortable and safe ride for each child. Meanwhile, drivers can upload necessary documents, such as their licenses and vehicle information, along with personal photos.</p>
                    <p>Our solution provides better communication between parents and drivers, creating a more collaborative environment. It ultimately brings significant benefits to our client by improving user satisfaction and increasing service reliability.</p>
                </div>
                <div class="section_content__item">
                    <h2 class="section_content__item-title">
                        LISTENING AND ADAPTING WITH HOPWAYS
                    </h2>
                    <p>In February 2024, we began our work by launching an important consultation with the Hopways team to understand their vision. During this initial meeting, we focused on discussing their needs and setting clear goals to ensure we were all on the same page. This collaborative approach helped us build a solid foundation for the project.</p>
                    <p>After our consultation, our team got to work developing a detailed step-by-step plan. We aimed to create each part of the system within a tight timeline while ensuring high quality. To do this, we used agile development practices. This method allowed us to stay flexible and adapt quickly to any new insights or changes that came up during the project.</p>
                    <p>Throughout the development process, we concentrated on providing a smooth user experience and strong functionality. We understood that these elements were crucial for Hopways to succeed in a competitive environment. By working in iterative cycles, we tested and refined the platform continuously based on feedback from both our team and the client. This commitment to quality ensured we built a system that truly met users’ needs.</p>
                    <p>By August 2024, after thorough testing and adjustments, we launched the complete system for live users. The initial feedback from both Hopways and their user base was very positive. Users found the platform easy to navigate, reliable, and effective in addressing their requirements. This early satisfaction was a promising sign of the platform’s potential impact.</p>
                    <p>From the client’s perspective, the system’s stability and scalability were significant achievements. Hopways appreciated how well the platform performed under various loads, which gave them confidence that it could grow with their business.</p>
                </div>
                <div class="section_content__item">
                    <h2 class="section_content__item-title">
                        A JOURNEY OF CONTINUOUS IMPROVEMENT
                    </h2>
                    <p>Since launching the Hopways platform, our WDEVS team has been all about supporting its growth and making it better. We work closely with Hopways to keep their platform fresh and competitive. This ongoing partnership lets us roll out new features based on what users want and what clients suggest, keeping our focus on their needs.</p>
                    <p>We’re also quick to tackle any technical requests, ensuring everything runs like a well-oiled machine. By handling these issues promptly, we help reduce downtime and make the user experience smoother. This way, Hopways can concentrate on delivering top-notch services to their users without getting bogged down by tech hiccups.</p>
                    <p>Our teamwork has been key in keeping the platform updated and in tune with Hopways’ evolving needs. As they grow and expand their services, we’re right there providing the technical support and enhancements they need to make it happen. This solid base allows Hopways to keep delivering real value to their users, which in turn boosts customer satisfaction and loyalty.</p>
                    <p>Being open to user feedback helps us stay nimble and ready to adapt to market changes. This flexibility gives Hopways the edge to innovate and shine in a crowded space, ultimately setting them up for long-term success. Together, we’re on a mission to drive progress and ensure that the Hopways platform remains a standout in the industry.</p>
                </div>
                <div>
                    <div class="footer-blog-item">
                        <div
                            class="btn-read-more primary contacts-button small"
                            id="show-modal"
                            @click="this.$router.push('/blog')"
                        >
                            <span class="button-content">
                                <span class="icon">
                                    <img
                                        v-svg-inline
                                        :data-src="require('@/assets/icons/rarr-icon.svg')"
                                        alt="" />
                                </span>
                                Read more our stories
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import Breadcrumbs from "/src/components/Breadcrumbs";
import {mapState} from "vuex";

export default {
    name: "BlogDrivingSalesHoliday",
    data() {
        return {
            backHistory: history.state.back,
            tags: ['Custom Web Development','Web Design'],
            scroll: false,
            position: null,
            breadcrumbs: [
                {
                    link: '/',
                    name: 'Home',
                },
                {
                    name: 'Blog',
                    link: '/blog',
                },
                {
                    name: 'HOPWAYS.COM: Simplifying school journeys for everyone',
                },
            ],
        };
    },
    components: {
        SidebarBlog,
        Breadcrumbs,
    },
    computed:{
        ...mapState(['blog'])
    },
    methods: {
        goToPath(tag){
            let post = this.blog.find(item => item.tags.includes(tag))
            this.$router.push(post.img_src)
        },
        back(){
            if(!document.referrer || this.backHistory !== '/blog'){
                Object.defineProperty(document, "referrer", {
                    get: function () {
                        return `${window.location.origin}/blog`;
                    },
                });
                this.$router.push('/blog')
                return
            } else{
                this.$router.go(-1)
            }
        },
        scrolling() {
            const scroll = document.documentElement.scrollTop;
            if (scroll > 50) {
                this.scroll = true;
            } else {
                this.scroll = false;
            }
        },
    },
    created() {
        console.log(history.state.back)
        // history.pushState('blog-home',window.location.href)
        window.history.scrollRestoration = "auto";
        window.addEventListener("scroll", this.scrolling);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.scrolling);
    },
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.article-blog-header {
    .article-blog-review__title {
        padding: 120px 0;
        text-align: end;
        text-transform: uppercase;
        color: $black;
        font-size: 32px;
        line-height: 111%;
        margin: 0;
        @include media(">=tablet") {
            font-size: 40px;
            max-width: 100%;
        }
        @include media(">=tablet-xl") {
            font-size: 56px;
        }
        @include media(">=widescreen") {
            font-size: 64px;
            max-width: 100%;
        }
    }
}

.footer-blog-item {
    display: block;
    padding-top: 20px;
    @include media(">=tablet") {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .article-blog-tags {
        margin-top: 40px;
        color: $red;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        @include media(">=tablet") {
            margin-top: 0;
            text-align: center;
        }
        span {
            margin-right: 20px;
            @include media(">=widescreen") {
                margin-right: 40px;
            }
        }
    }
}

.article-blog-page {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .link{
        color:$red;
        cursor:pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .btn-read-more {
        .button-content {
            border-radius: 26px;
            padding: 2px 12px 3px;
            background-color: $red;
            color: $white;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
            width: max-content;
            background-image: linear-gradient($darkRed, $darkRed);
            background-repeat: no-repeat;
            transition: background-size 0s, color 0s;
            background-size: 0 100%;
            @include media(">=tablet-xl") {
                padding: 6px 24px 8px;
            }
            &:hover {
                background-size: 100% 100%;
                color: $white;
            }
        }
        .icon {
            padding-top: 6px;
            margin-right: 15px;
            transform: rotateY(180deg);
        }
        a {
            color: $white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .go-back-to-read {
        padding: 16px 0;
        border-bottom-left-radius: 26px 26px;
        border-bottom-right-radius: 26px 26px;
        position: fixed;
        background: $white;
        z-index: 50;
        width: 100vw;
        transition: all 0.3s;
        @include media(">=tablet-xl") {
            padding: 16px 0;
        }
        &.scrolling {
            padding: 5px 0;
            border-bottom: 1px solid $red;
            @include media(">=tablet-xl") {
                padding: 5px 0;
            }
        }
    }
}
.article-blog {
    &.section_holder {
        display: flex;
        flex-direction: column-reverse;
        @include media(">=tablet-xl") {
            flex-direction: row;
        }
    }
    .section_title {
        margin-right: 0;
        width: 100%;
        @include media(">=tablet-xl") {
            margin-right: 43px;
            width: 35%;
        }
    }
    .section_content {
        margin-bottom: 60px;
        display: block;
        margin-left: 0;
        width: 100%;
        a {
            color: $red;
        }
        &__item {
            margin-bottom: 40px;
            @include media(">=tablet") {
                margin-bottom: 60px;
            }
            @include media(">=desktop") {
                margin-bottom: 120px;
            }
            &-title {
                &-icon {
                    flex-shrink: 0;
                    width: 400px;
                    height: auto;
                    margin-right: 15px;
                    @media all and (max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                    }
                    svg {
                        max-width: 100%;
                        width: 100%;
                        max-height: 100%;
                        height: 100%;
                    }
                }
                &--icon {
                    display: flex;
                    align-items: flex-end;
                    @media all and (max-width: 767px) {
                        flex-wrap: wrap;
                    }
                    @media (min-width: 992px) and (max-width: 1199px) {
                        flex-wrap: wrap;
                    }
                }
            }
            &-slider {
                position: relative;
            }
            &-link {
                display: inline-block;
                &:hover {
                    text-decoration: none;
                }
            }
            blockquote {
                margin: 25px 0;
                border-left: 3px solid lighten($base-text-color, 25%);
                padding: 10px 25px;
                color: lighten($base-text-color, 25%);
            }
        }
        @include media(">=tablet-xl") {
            margin-left: 37px;
            width: 65%;
            margin-bottom: 0;
        }
        &__paragraph {
            display: flex;
            margin-bottom: 45px;
            &-icon {
                flex-shrink: 0;
                display: inline-block;
                width: 65px;
                height: 65px;
                margin-right: 10px;
                vertical-align: middle;
                @media all and (min-width: 768px) {
                    width: 100px;
                    height: 100px;
                    margin-right: 15px;
                }
                svg {
                    max-width: 100%;
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.article-blog__banner {
    position: relative;
    height: 300px;
    margin-bottom: 60px;
    @include media(">=tablet") {
        height: 500px;
    }
    @include media(">=desktop") {
        height: 600px;
        margin-bottom: 120px;
    }
    &--eclipse {
        z-index: 1;
        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.3);
        }
        .article-blog__banner-title {
            z-index: 3;
        }
    }
    &-img,
    picture {
        display: block;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.article-blog__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
        bottom: 40px;
        font-size: 40px;
        left: 40px;
        max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
        bottom: 80px;
        left: 40px;
        font-size: 56px;
        max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
        bottom: 80px;
        left: 80px;
        font-size: 64px;
        max-width: 1150px;
    }
}
.article-blog__img,
picture {
    margin: 0 0 40px;
    display: block;
}
.article-blog__customer {
    em {
        display: block;
        margin-top: 32px;
    }
    .button {
        margin-top: 40px;
    }
}
</style>
