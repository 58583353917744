<template>
  <section class="mfo__banner">
    <picture class="mfo__banner-img">
      <source
          media="(max-width: 768px)"
          srcset="
            @/assets/magento/magento-migration/magento-migration_small.webp    1x,
            @/assets/magento/magento-migration/magento-migration_small@2x.webp 2x
          "
      />
      <source
          media="(max-width: 768px)"
          srcset="
            @/assets/magento/magento-migration/magento-migration_small.jpg    1x,
            @/assets/magento/magento-migration/magento-migration_small@2x.jpg 2x
          "
      />

      <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcset="
            @/assets/magento/magento-migration/magento-migration_medium.webp    1x,
            @/assets/magento/magento-migration/magento-migration_medium@2x.webp 2x
          "
      />
      <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcset="
            @/assets/magento/magento-migration/magento-migration_medium.jpg    1x,
            @/assets/magento/magento-migration/magento-migration_medium@2x.jpg 2x
          "
      />

      <source
          srcset="
            @/assets/magento/magento-migration/magento-migration.webp    1x,
            @/assets/magento/magento-migration/magento-migration@2x.webp 2x
          "
      />
      <source
          srcset="
            @/assets/magento/magento-migration/magento-migration.jpg    1x,
            @/assets/magento/magento-migration/magento-migration@2x.jpg 2x
          "
      />

      <img
          src="@/assets/magento/magento-migration/magento-migration.jpg"
          alt="Migration to Magento from different eCommerce platforms"
          loading="lazy"
          width="1920"
          height="640"
      />
    </picture>
    <h1 class="mfo__banner-title">
      Migration to Magento from Different eCommerce Platforms
    </h1>
  </section>

  <section-layout name="mfo__first" title="" margin="true">
    <p>At present, Magento is the ultimate eCommerce platform - providing its clients with top-notch,  flexible business solutions. It allows you to use a powerful catalog of various tools and custom features which  help you achieve your marketing & sales goals.</p>
    <p>Our professional developers will work with you to analyze your current website structure, data logic and business processes, to flawlessly migrate your web store, while ensuring your data integrity.</p>
    <p>If you are migrating from a different eCommerce platform, WDevs Magento experts will break the process down to smaller tasks to ensure your successful migration. With more than 15 years of experience, we are proud to have provided immaculate Magento migration services for leading eCommerce companies worldwide.</p>
    <p><b>Why Should You Migrate to Magento?</b></p>
    <p>Magento is the most up to date, powerful, open-source e-commerce platform available on the market, and it  provides all the necessary out-of-the-box features to set up an e-store of any scale:</p>
  </section-layout>

  <section-layout name="mfo__innovative" title="Innovative Tools & Features" margin="true">
    <p>With each new version, Magento brings something even more innovative to the table, and provides a wider spectrum  of features to enhance your eCommerce opportunities, as well as boost your sales. Whether it’s in relation to product catalog management, payment-processing gateways, or order delivery management - you will always be provided with an effective eCommerce solution.</p>
  </section-layout>

  <section-layout name="mfo__opportunities" title="Ample opportunities for customization" margin="true">
    <p>Magento offers fantastic customization capabilities, allowing you to design individual themes, layouts and templates, as well as  fully customize them to benefit your business & share your brand’s values with all your customers.</p>
  </section-layout>

  <section-layout name="mfo__improved-performance" title="Improved Performance" margin="true">
    <p>With Magento you can be sure that your website delivers a faster response per request, increased page loading speed, smooth checkout process, and shows advanced performance metrics.</p>
  </section-layout>

  <section-layout name="mfo__secure-running" title="Secure Running" margin="true">
    <p>Magento is visibly more secure than most of the other eCommerce platforms due to a wide community of professional support and regular safety patch updates. Moreover, Magento's security options allow you to customize the level of access that your employees, or 3rd party agencies have to your website's backend.</p>
  </section-layout>

  <section-layout name="mfo__migration" title="Migration Steps" margin="true">
    <p>Each particular case depends on the specifics of your existing platform; as well as the tools, extensions and integrations implemented. But rest assured, that no matter how demanding the initial task is, we will ensure that your products, order & shipping details, and customer data are smoothly and securely migrated from your current platform.</p>
    <p>We recommend  the  following steps:</p>
    <ul>
      <li>Understanding your goals & requirements</li>
      <li>Choosing Design & Layout</li>
      <li>Data Migration</li>
      <li>Customization & Optimization</li>
      <li>Deployment</li>
      <li>Maintenance & Support</li>
    </ul>
    <p>A successful migration to Magento requires a thorough plan and initial continued  testing, which is why we specifically recommend contacting Magento experts with proven track records in  migration projects. WDevs is an official Magento partner, and subsequently our team of certified Magento developers has already helped numerous major eCommerce players from various industries, migrate to Magento over the years.</p>
    <a href="#" @click.prevent="goBack()" class="button primary back">Back to Magento</a>
  </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";

export default {
  data(){
    return{
      position:null,
    }
  },
  components: {
    SectionLayout,
  },
  methods:{
    goBack(){
      if(!document.referrer){
        Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/magento` }})
        this.$router.push('/magento')
      }else{
        this.$router.go(-1)
      }
    }
  },
  created(){
    window.history.scrollRestoration = "auto";
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.mfo {
  &__banner {
    height: 300px;
    position: relative;
    @include media(">=tablet") {
      height: 500px;
    }
    @include media(">=desktop") {
      height: 640px;
      margin-bottom: 60px;
    }
    &-img {
      display: block;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
      bottom: 40px;
      font-size: 40px;
      left: 40px;
      max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
      bottom: 80px;
      left: 40px;
      font-size: 56px;
      max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
      bottom: 80px;
      left: 80px;
      font-size: 64px;
      max-width: 1150px;
    }
  }

  &__first,
  &__innovative,
  &__opportunities,
  &__improved-performance,
  &__secure-running,
  &__migration {
    .section_content {
      display: block;
    }
    ul{
      li{
        margin-bottom:5px;
      }
    }
    a {
      color: $red;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .button{
      cursor: pointer;
      margin-top:40px;
      color:$white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      padding: 10px 24px;
      border-radius:26px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
</style>
