<template>
<div class="epr-page" @scroll="scrolling()">
    <breadcrumbs
        :breadcrumbs="breadcrumbs"
        :scroll="scroll"
        @goBack="back"
    />
    <section class="epr__banner">
      <img
          class="security-audit__banner-img"
          src="@/assets/blog/epr/epr-article-top-img@2x.jpg"
          alt="A complete introduction to ERP integration with eCommerce"
          loading="lazy"
          modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
      />
      <h1 class="epr__banner-title">
         A COMPLETE INTRODUCTION TO ERP INTEGRATION WITH ECOMMERCE
      </h1>
    </section>
    <div class="container blog-epr section_holder">
      <div class="section_title">
        <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
      </div>
      <div class="section_content">
        <div class="section_content__item">
          <p>
            In this article, our WDevs team will provide a complete overview of
            eCommerce-ERP integration and how it can assist in automating your
            business processes, as well as managing all your eCommerce
            activities, - so hop on & let’s take a look at custom developing the
            integration process with external ERP systems.
          </p>
        </div>
        <div class="section_content__item">
          <h2 class="section_content__item-title">WHAT IS ERP INTEGRATION?</h2>
          <p>
            The first thing we need to define is, what exactly does ERP mean?
            ERP stands for Enterprise Resource Planning – it is a system of
            integrated applications to automate back-office business processes
            such as product distribution, supply, payments, CRM, commerce,
            reporting etc. ERP modules enable the exchange of data between the
            CMS platform & numerous 3rd party solutions commonly used in
            corporate activities.
          </p>
          <p>
            Quite often, eCommerce business owners go looking for ready-made
            solutions to integrate their existing business system with any ERP.
            And here, it is important to note that ERP systems are usually used
            by clients who already have well-established business processes and
            a certain level of scaling. In this instance, we are talking; at the
            very least, about SME/SMB enterprises with a thoroughly developed
            sales process. For small businesses, it is unfortunately not a good
            solution, since it will most likely complicate the structure and
            negatively affect the company's costs.
          </p>
          <p>
            Why is this so? The implementation of the ERP system requires a
            considerable amount of time and effort. Ready-made solutions that
            can be found on the market today, as a rule, solve a limited number
            of issues for your business, therefore more and more successful
            eCommerce clients are turning to custom-tailored ERP solutions that
            would consider the needs and specifics of their business
            organization, provide fully Integrated information between all
            elements and departments, as well as increase efficiency by
            simplifying and automating procedures within the company.
          </p>
          <p>
            With over 15 years of experience working with various eCommerce
            products, Wdevs has developed a unique solution for secure and
            efficient data synchronization, which we implement collaboratively
            with our long-trusted partners, <a
              href="https://firebearstudio.com/"
              target="_blank"
              > Firebear Studio.</a
            >
          </p>
        </div>
        <div class="section_content__item">
          <h2 class="section_content__item-title">TYPES OF ERP INTEGRATIONS</h2>
          <p>Firstly, let's find out what ERP (3rd party) solutions are?</p>
          <p>
            Depending on the needs of the business, these solutions can be
            divided into several types:
          </p>
          <ul>
            <li>
              CRM – ERP integration (Customer Relationship Management – ERP).
              The CRM system is responsible for the customer data, but to track
              orders & shipping management, you must connect it to an ERP. This
              solution allows you to collect & analyze information regarding
              demand (CRM) & supply (ERP) in one place.
            </li>
            <li>
              eCommerce – ERP integration. Online businesses always arrive at
              that point when well-organized data collection means everything.
              Order tracking, shipping, inventory, customer data – if the data
              synchronization process is automated, you won’t need to spend
              extra time analyzing all these tasks manually.
            </li>
            <li>
              BI – ERP integration (Business Intelligence - ERP). Business
              intelligence software combines business analytics, data mining &
              visualization, as well as data infrastructure to help companies
              work out balanced and fact-based decisions. ERP integration helps
              your team to gain full access to this data at any time, thus
              improving your team management processes & communication.
            </li>
            <li>
              PM – ERP integration (Project Management - ERP). This type of
              integration allows you to aggregate all your team’s tasks,
              processes and workflows in one place.
            </li>
            <li>… and many others.</li>
          </ul>
          <p>Functionally, such solutions can be divided into 3 types:</p>
          <ul>
            <li>Data import synchronization from eCommerce to ERP</li>
            <li>Data export synchronization from ERP to eCommerce</li>
            <li>
              Multi-directional synchronization (from eCommerce to ERP and vice
              versa).
            </li>
          </ul>
        </div>
        <div class="section_content__item">
          <h2 class="section_content__item-title">
            CHOOSE THE MOST SUITABLE ERP INTEGRATION FOR YOUR BUSINESS WITH
            WDEVS
          </h2>
          <p>
            So, what is the unique solution our team has developed? We offer a
            comprehensive integration of your ERP system (3rd party solution)
            with Magento, which consists of 4 stages. Let's take a closer look
            at each of them:
          </p>
          <h3>Step 1 - Connection (receiving data from the ERP system).</h3>
          <p>
            In today's digital world, there are an endless number of 3rd party
            solutions used for integrating with popular eCommerce platforms,
            such as Magento, Shopify, Shopware, WooCommerce, and more. In terms
            of Product Management, the data structure for integration can be
            represented as categories: products, listings, attributes,
            stores/warehouses.
          </p>
          <p>
            Our many years of experience in integration with leading ERP systems
            (NetSuite, Merlin, Microsoft Dynamics, Salesforce, and more
            lesser-known API-based systems) allows us to develop and create a
            connector suitable for your specific case, or adapt an existing
            connector to a specific ERP system.
          </p>

          <div class="epr__img">
            <img
                src="@/assets/blog/images/epr@2x.jpg"
                alt="Most common ERP systems and CMS platforms"
                title="ERP integration"
                loading="lazy"
                modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
          </div>
          <h3>
            Step 2 - Mapping ERP data with Magento or other eCommerce systems.
          </h3>
          <p>
            It happens frequently that attributes of products in the ERP system
            differ from the attributes of the same products in the eCommerce
            solution. Here’s an example: in your CMS system, there is a fixed
            set of colors for a product, and in an ERP system, there will be a
            different combination of colors. In most CMS systems, there is no
            ready-made solution for such a task. If you are therefore looking
            for the ultimate solution, then you need to either map the missing
            colors or expand your eCommerce system and add multi-color
            solutions. The same algorithm works for mapping information about
            materials, sizes, and more. Data mapping is limited only by the
            capabilities of the developer and manufacturer of a particular
            solution, therefore setting up automatic mapping involves the
            crucial step of customizing the ERP integration solution to meet the
            needs of your business. Our team, together with our long-trusted
            partner - <a href="https://firebearstudio.com/" target="_blank">firebearstudio.com</a> - has developed a unique innovative
            solution that uses our partners' <a href="https://firebearstudio.com/the-improved-import.html" target="_blank">synchronization module</a> and involves
            the creation and customization of a visualized data mapping process.
          </p>
          <p>
            In some cases, if the client needs one-time synchronization, or an
            out-of-the-box solution which he does not plan to customize further
            in the future, our WDevs team builds the data mapping at the site
            server level.
          </p>
          <h3>Step 3 - Customization.</h3>
          <p>
            Usually, this stage involves close interaction with the client and
            his team, since it is the client who determines which attributes are
            of priority. Together with you, we are building a data structure of
            all the necessary attributes in the product object, in the ERP
            system (3rd party solution) and the eCommerce system for further
            synchronization.
          </p>
          <h3>And finally, step 4 - Synchronization.</h3>
          <p>
            At this stage, we choose the type of synchronization. It could be
            any one of the following:
          </p>
          <ul>
            <li>
              Asynchronous - for example, information about new orders or a
              change in the status of an order in your store is transmitted to
              the ERP system during synchronization once per hour, or once a
              day.
            </li>
            <li>
              Synchronous - synchronization of information in regards to
              creating a new order, or alternatively changing the status of an
              existing one, occurs in ERP at the moment these changes occur.
            </li>
            <li>
              Two-way synchronization. This two-way synchronization option helps
              you integrate both your ERP and CRM data and create one universal
              system for easier data access.
            </li>
            <li>
              One-way synchronization. It loads all mapped ERP data from your
              ERP into an eCommerce solution.
            </li>
          </ul>
          <p>
            Many customers settle for a complex solution - two-way
            synchronization, but there are cases when it is unnecessary. For
            example, if an eCommerce solution operates on products that are
            accounted for by a 3rd party stock manager (ERP), then one-way
            asynchronous synchronization is sufficient. Moreover, 3rd party
            systems sometimes impose restrictions - this, in turn, requires
            complex customization for full two-way synchronization and
            therefore, higher costs for the client.
          </p>
          <p>
            The decision on the type of integration is always made together with
            the client, based on their specific case and needs.
          </p>
        </div>
        <div class="section_content__item">
          <h2 class="section_content__item-title">PRICE</h2>
          <p>
            The main question that concerns most clients when they think about
            integrating their eCommerce solution with an ERP system, is the
            cost. In the diagram below, you can see the cost breakdown as a
            percentage for each integration step.
          </p>
          <div class="epr__img">
            <img
                src="@/assets/blog/images/epr-price@2x.jpg"
                alt="Price breakdown for WDevs ERP integration services"
                title="Price Breakdown"
                loading="lazy"
                modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
          </div>
          <p>
            Connection of the eCommerce system data with ERP (3rd party
            solution) will take about 13% of the budget. Mapping is a crucial
            stage of any integration project; if it is configured from scratch -
            it is about 40% of the project budget. Customization is equally
            important if you seek a ready-made solution for your ERP system,
            taking into consideration the specifics of its functionality - this
            could take up another 40% of the budget. Finally there’s
            synchronization, which, taking into account the work already done
            during the previous steps, only takes 7%.
          </p>
          <p>
            In terms of price, if you are a rare owner of a barely customized
            ERP and eCommerce solution, then we most likely already have ready
            connectors to suit your needs, and therefore the estimated cost of
            the integration will only be about <strong><em>1000 USD</em></strong>. Timescales of the
            development and implementation of a standard integration are 1-2
            weeks.
          </p>
          <p>
            Admittedly though, these are rare cases, since more often than not,
            most clients attempt to customize and personalize their own online
            business tools (eCommerce & 3rd party solutions), and subsequently,
            we always state that each case requires an individual approach.
            After analyzing the input data, your business model and data
            structure, our team will provide you with ideas for the most
            cost-effective solution. Roughly, more or less complex customization
            takes up to a month.
          </p>
          <p>
            Based on our experience, the quickest and most inexpensive
            integration we've ever done was SalesForce integration. The Client
            considered implementing ERP for the stock management, and he used
            default salesforce essence. Using Firebear mapping and our own
            connector, we integrated everything within a week, and the total
            cost for the client was only $2000 for the custom development work +
            $599 for
            <a
              href="https://firebearstudio.com/the-improved-import.html"
              target="_blank"
              >import-export module</a
            >
          </p>
          <p>
            The most expensive integration we've ever done was a
            Magento/NetSuite integration, and it took over <strong><em>700 hours</em></strong> of
            development (>$35K budget). The Client is a 10+ y.o. online platform
            for food and consumer goods, which is a marketplace for many
            retailers, with 50K+ purchases a month. They had used NetSuite for
            over 5 years And they had also constantly improved and upgraded
            their business model; therefore both Magento and Netsuite were
            heavily customized, and it was not a simple task to manage a
            synchronous integration. Unfortunately, such complex integration
            can't be managed within a few weeks, and it requires careful
            research and complicated mapping of various parameters, which we
            have successfully implemented numerous times.
          </p>
        </div>
        <div class="section_content__item">
          <h2 class="section_content__item-title">CONCLUSION</h2>
          <p>
            To sum it up, a comprehensive ERP system integration with an
            eCommerce solution will definitely benefit your business by
            automating your sales and product management processes & by
            gathering all your data in one place. You can increase the workflow
            of nearly every department in your company, improve collaborations
            and simplify the way your team members perform daily tasks.
          </p>
          <p>
            The WDevs team has synthesized its many years of professional
            experience, & knowledge of various ERP’s (3rd party) and eCommerce
            systems, into the ultimate most effective and customer
            value-oriented solution, to cover all your integration needs.
          </p>
          <p>
            <router-link to="/contacts">Contact us</router-link> today to find
            out more about our work, the services we offer and how we can help
            you to develop a successful eCommerce-ERP integration.
          </p>
        </div>
        <div>
          <div class="footer-blog-item">
            <div
              class="btn-read-more primary contacts-button small"
              id="show-modal"
              @click="this.$router.push('/blog')"
            >
              <span class="button-content">
                <span class="icon"
                  ><img
                    v-svg-inline
                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                    alt=""
                /></span>
                Read more our stories
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import { mapState } from "vuex";
import Breadcrumbs from "/src/components/Breadcrumbs";

export default {
  name: "BlogEpr",
  data() {
    return {
      scroll: false,
      backHistory:history.state.back,
      tags:['ERP integration','Netsuite','Salesforce','Merlin','Connectors','Synchronization'],
      position: null,
      text: `
      Would you like to receive more examples of what we are able to help you achieve?<br />
      Do you still have doubts about your idea and believe that it will be too hard to implement?<br/>
      Would you like us to propose ways of turning your ideas into reality?
      `,
      breadcrumbs: [
        {
          link: '/',
          name: 'Home',
        },
        {
          name: 'Blog',
        },
        {
          name: 'ERP',
        },
      ],
    };
  },
  components: {
    SidebarBlog,
    Breadcrumbs,
  },
   computed:{
    ...mapState(['blog'])
  },
  methods: {
  goToPath(tag){
      let post = this.blog.find(item => item.tags.includes(tag))
      this.$router.push(post.img_src)
    },
   back(){
    if(!document.referrer || this.backHistory !== '/blog'){
        Object.defineProperty(document, "referrer", {
          get: function () {
            return `${window.location.origin}/blog`;
          },
        });
        this.$router.push('/blog')
        return
      } else{
        this.$router.go(-1)
      }
    },
    scrolling() {
      const scroll = document.documentElement.scrollTop;
      if (scroll > 50) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    },
  },
  created() {
    // history.pushState('epr',window.location.href)
          console.log(history.state.back)
    window.history.scrollRestoration = "auto";
    window.addEventListener("scroll", this.scrolling);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.epr-header {
  .epr__title {
    padding: 120px 0;
    text-transform: uppercase;
    text-align: end;
    color: $black;
    font-size: 32px;
    line-height: 111%;
    margin: 0;
    @include media(">=tablet") {
      font-size: 40px;
      max-width: 100%;
    }
    @include media(">=tablet-xl") {
      font-size: 56px;
    }
    @include media(">=widescreen") {
      font-size: 64px;
      max-width: 100%;
    }
  }
}
.footer-blog-item {
  display: block;
  padding-top: 20px;
  @include media(">=tablet") {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .epr-tags {
    margin-top: 40px;
    color: $red;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    @include media(">=tablet") {
      margin-top: 0;
      text-align: center;
    }
    span {
      margin-right: 20px;
      @include media(">=widescreen") {
        margin-right: 40px;
      }
    }
  }
}

.epr-page {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  a.outside_link {
    color: $black !important;
    font-style: italic;
  }
  .btn-read-more {
    .button-content {
      border-radius: 26px;
      padding: 2px 12px 3px;
      background-color: $red;
      color: $white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      width: max-content;
      background-image: linear-gradient($darkRed, $darkRed);
      background-repeat: no-repeat;
      transition: background-size 0s, color 0s;
      background-size: 0 100%;
      @include media(">=tablet-xl") {
        padding: 6px 24px 8px;
      }
      &:hover {
        background-size: 100% 100%;
        color: $white;
      }
    }
    .icon {
      padding-top: 6px;
      margin-right: 15px;
      transform: rotateY(180deg);
    }
    a {
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .go-back-to-read {
    padding: 16px 0;
    border-bottom-left-radius: 26px 26px;
    border-bottom-right-radius: 26px 26px;
    position: fixed;
    background: $white;
    z-index: 50;
    width: 100vw;
    transition: all 0.3s;
    @include media(">=tablet-xl") {
      padding: 16px 0;
    }
    &.scrolling {
      padding: 5px 0;
      border-bottom: 1px solid $red;
      @include media(">=tablet-xl") {
        padding: 5px 0;
      }
    }
  }
}
.blog-epr {
  &.section_holder {
    display: flex;
    flex-direction: column-reverse;
    @include media(">=tablet-xl") {
      flex-direction: row;
    }
  }
  .section_title {
    margin-right: 0;
    width: 100%;
    @include media(">=tablet-xl") {
      margin-right: 43px;
      width: 35%;
    }
  }
  .section_content {
    margin-bottom: 60px;
    display: block;
    margin-left: 0;
    width: 100%;
    a {
      color: $red;
    }
    &__item {
      h3{
        font-size: 20px;
      }
      margin-bottom: 40px;
      @include media(">=tablet") {
        margin-bottom: 60px;
      }
      @include media(">=desktop") {
        margin-bottom: 120px;
      }
      &-title {
        font-size: 24px;
      }
    }
    @include media(">=tablet-xl") {
      margin-left: 37px;
      width: 65%;
      margin-bottom: 0;
    }
  }
}

.epr__banner {
  height: 300px;
  position: relative;
  margin-bottom: 60px;
  @include media(">=tablet") {
    height: 500px;
  }
  @include media(">=desktop") {
    height: 600px;
    margin-bottom: 120px;
  }
  &-img,
  picture {
    display: block;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.epr__banner-title {
  text-transform: uppercase;
  color: $white;
  font-size: 32px;
  line-height: 111%;
  position: absolute;
  bottom: 20px;
  left: 16px;
  max-width: calc(100% - 32px);
  margin: 0;
  @include media(">=tablet") {
    bottom: 40px;
    font-size: 40px;
    left: 40px;
    max-width: calc(100% - 80px);
  }
  @include media(">=tablet-xl") {
    bottom: 80px;
    left: 40px;
    font-size: 56px;
    max-width: calc(100% - 80px);
  }
  @include media(">=widescreen") {
    bottom: 80px;
    left: 80px;
    font-size: 64px;
    max-width: 1150px;
  }
}
.epr__first,
.epr__innovations,
.epr__process,
.epr__customer {
  p {
    margin-bottom: 0;
  }
  ul {
    padding-left: 30px;
    margin-bottom: 0;
    ul {
      margin-top: 32px;
      li {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    li {
      margin-bottom: 32px;
    }
  }

  a {
    color: $red;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .section_content {
    flex-direction: column;
    align-items: flex-start;
  }
  .section_title {
    margin-bottom: 30px;
    @include media(">=tablet") {
      margin-bottom: 60px;
    }
  }
}
.epr__img {
  margin: 40px 0;
  display: block;
  picture {
    display: block;
  }
}
.epr__customer {
  em {
    display: block;
    margin-top: 32px;
  }
  .button {
    margin-top: 40px;
  }
}
</style>
