<template>
    <div class="article-blog-page" @scroll="scrolling()">
        <breadcrumbs
            :breadcrumbs="breadcrumbs"
            :scroll="scroll"
            @goBack="back"
        />
        <section class="article-blog__banner article-blog__banner--eclipse">
            <img
                class="article-blog__banner-img"
                src="@/assets/blog/shopify-limitations-for-e-commerce.jpg"
                alt="Hiring with Outsourcing Company vs Recruiting Agency"
                loading="lazy"
                modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
            <h1 class="article-blog__banner-title">
                ARE SHOPIFY'S LIMITATIONS HOLDING YOUR E-COMMERCE BUSINESS BACK? (WHEN & WHY YOU SHOULD CONSIDER MIGRATION TO ANOTHER PLATFORM)
            </h1>
        </section>
        <div class="container article-blog section_holder">
            <div class="section_title">
                <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
            </div>
            <div class="section_content">
                <div class="section_content__item">
                    <p>
                        One of the biggest e-commerce platforms today is Shopify. Many merchants choose this option for its numerous tools and exclusive features that make it easy to set up an online storefront and manage it efficiently. Still, at a certain point in your business development, a Shopify store may not be enough. But first, let’s point out the popular reasons why e-commerce market players select Shopify:
                    </p>
                    <ul>
                        <li>Easy to set up and tune;</li>
                        <li>Customer support;</li>
                        <li>A wide range of themes to choose from;</li>
                        <li>Reporting & analytics tools;</li>
                        <li>User-friendly design;</li>
                        <li>Access to multiple payment integrations.</li>
                    </ul>
                    <p>
                        When your business starts growing rapidly, systems that once worked well may no longer be the best option for your increasing audiences, transactions, and customer engagement. Don’t get us wrong - Shopify might be the best e-commerce solution for setting up a small business in a very short time. But then there are some disadvantages of Shopify to consider if you’re planning to scale up your online sales platform. So, let’s find out these downsides:
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/shopify-limitations-for-e-commerce/shopify-limitations-for-e-commerce-img-1.jpg"
                            alt="Article image 1"
                            title="Article image 1"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        Limited Customization and Flexibility
                    </h2>
                    <p>
                        One of the significant drawbacks of Shopify is its limited customization options compared to Magento and Adobe Commerce. While Shopify offers various themes and apps, the customization might not meet the specific needs of some businesses, especially those with unique or complex requirements.
                    </p>
                </div>
                <div class="section_content__item">
                    <h2 class="section_content__item-title">
                        Payment Processing Limitations and Transaction Fees
                    </h2>
                    <p>
                        Shopify charges a transaction fee for each sale. This is a fee that you pay for using the Shopify platform. The amount of this fee varies depending on the package you choose, but these fees may surprise you if you don't have a comprehensive understanding of how much you pay.
                    </p>
                    <p>
                        Another thing is Shopify setting several limitations for payment services you use. For example, geographical restrictions. Certain payment methods may only be available in specific regions or countries. This can depend on both the payment gateway's availability and local regulations. Also, merchants with a history of high chargeback rates may have limited access to certain payment gateways due to risk management concerns.
                    </p>
                    <p>
                        Moreover, the use of the Shopify Afterpay Service can be quite complicated in reality since Shopify applies a commission for each payment. Afterpay is a way for customers to shop online, especially if they want to buy products that they can’t quite afford yet. Repayments are spread evenly across fortnightly installments, so the customer doesn’t feel the full financial pressure of a purchase in one go. In the case you use Afterpay Service, it is hard to define the specific time when the payment transaction is planned to be processed. There are <a href="https://help.shopify.com/en/manual/products/purchase-options/subscriptions/setup">eligibility requirements</a> you need to follow for setting up subscription plans.
                    </p>
                    <p>
                        Shopify Payments Service is not available in all countries, which can be a significant limitation for international businesses. If your country isn't supported, you'll be forced to use third-party payment gateways, and that can introduce extra fees for your business. Here is the list of supported countries to accept payments according to <a href="https://help.shopify.com/en/manual/payments/shopify-payments/supported-countries">the Shopify Help Center</a>:
                    </p>
                    <ul class="section_content__list section_content__list--two-columns">
                        <li>Australia</li>
                        <li>Austria</li>
                        <li>Belgium</li>
                        <li>Canada</li>
                        <li>Czechia</li>
                        <li>Denmark</li>
                        <li>Finland</li>
                        <li>France</li>
                        <li>Germany</li>
                        <li>Hong Kong SAR</li>
                        <li>Ireland</li>
                        <li>Italy</li>
                        <li>Japan</li>
                        <li>Netherlands</li>
                        <li>New Zealand</li>
                        <li>Portugal</li>
                        <li>Romania</li>
                        <li>Singapore</li>
                        <li>Spain</li>
                        <li>Sweden</li>
                        <li>Switzerland</li>
                        <li>United Kingdom</li>
                        <li>United States</li>
                    </ul>
                </div>
                <div class="section_content__item">
                    <h2 class="section_content__item-title">
                        Limited Hosting and Product Management Ability
                    </h2>
                    <p>
                        While Shopify handles hosting for its users, this can result in a lack of control and transparency over your website's server infrastructure. Take, for example, limited server control. Shopify hosts all the websites on its own servers, which means users have limited control over server configurations. This can be frustrating for businesses with specific hosting requirements or those looking to fine-tune server settings for optimal performance.
                    </p>
                    <p>
                        For large-scale businesses with expansive product databases, Shopify's limitations become even more apparent. Shopify's product range system may not adequately handle the complexity of large inventories with numerous product options. Managing and organizing these product options can become really time-consuming.
                    </p>
                    <p>
                        Product import is also a tricky question when it comes to dealing with diverse product data. It usually requires custom data handling processes, and you might need a complex integration with your internal ERP systems for order management, inventory control, etc. Shopify's capabilities in this area are noticeably limited compared to more comprehensive enterprise-level solutions provided by Adobe Commerce Cloud or Magento.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/shopify-limitations-for-e-commerce/shopify-limitations-for-e-commerce-img-2.jpg"
                            alt="Article image 2"
                            title="Article image 2"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        Data Control
                    </h2>
                    <p>
                        One of the significant concerns for Shopify-based e-commerce merchants is the limited control they have over their data. When using Shopify, your store's data is hosted on their servers. This means you have less control over your data storage. Additionally, businesses often need custom data handling solutions to integrate with other systems, manage complex inventory, or automate business processes. Shopify's data handling capabilities are limited compared to other enterprise-level platforms.
                    </p>
                    <p>
                        Sounds like a difficult puzzle to solve? Well, there’s more to come. If, for any reason, you decide to move your store to a different platform, retrieving and migrating your data can become a complex, time-consuming, and potentially expensive process. Shopify provides very few data points to the users, while missing essential information components like product images, product descriptions, pricing, etc. So, for a growing business looking to control everything it is worth considering migration to a self-hosted platform.
                    </p>
                    <p>
                        Of course, the migration process may seem a bit confusing to those business owners who don’t want to spend days and hours digging into every technical detail. That’s why your best option in this case is to reach out to an experienced Magento Development team that will make the smooth and safe data transfer for you. At <b>WDEVS</b>, we ensure a full-scale migration from Shopify to any other online platform chosen by you, including your products, customers, orders, data, and a variety of entities to your new online store. <a href="/contacts">Contact us today</a> to get started.
                    </p>
                </div>
                <div class="section_content__item">
                    <h2 class="section_content__item-title">
                        Prohibited Items & Businesses
                    </h2>
                    <p>
                        Shopify allows you to sell many different types of products, that’s true. That’s why you might be surprised to know that, at the same time, it has one of the longest lists of prohibited and restricted items out of any eCommerce platform. From financial services to regulated products, there are hundreds of them. So, if your business manages any of those, Shopify won't let you sell them. Here’s a sample list of prohibited products including the risk products that Shopify is not able to cover:
                    </p>
                    <ul class="section_content__list section_content__list--two-columns-tablet-desktop">
                        <li>Alcoholic beverages and products</li>
                        <li>Apparel</li>
                        <li>Artwork</li>
                        <li>Automotive and Powersports</li>
                        <li>Batteries</li>
                        <li>CBD products</li>
                        <li>Cosmetics</li>
                        <li>Dietary supplements and prescription medicines</li>
                        <li>Digital products or goods</li>
                        <li>Drugs and drug paraphernalia</li>
                        <li>Electronics</li>
                        <li>Explosives, weapons, and related items</li>
                        <li>Export controlled items</li>
                        <li>Flammable and pressurized goods</li>
                        <li>Food and beverage</li>
                        <li>Jewelry and precious gems</li>
                        <li>Materials depicting illegal acts</li>
                        <li>Medical devices and accessories</li>
                        <li>Monetary or items used as payment</li>
                        <li>Plant and seed products</li>
                        <li>Sex and sensuality products</li>
                        <li>Subscription products</li>
                        <li>Tobacco and tobacco-related products</li>
                        <li>Warranties, service plans, contracts, and guarantees</li>
                        <li>Wood products</li>
                    </ul>
                    <p>We recommend checking with <a href="https://help.shopify.com/en/manual/markets/markets-pro/prohibited-items">the actual information</a> provided and regularly updated by Shopify.</p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/shopify-limitations-for-e-commerce/shopify-limitations-for-e-commerce-img-3.jpg"
                            alt="Article image 3"
                            title="Article image 3"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        Why Can My Products Be Banned?
                    </h2>
                    <p>
                        The common reasons your product can be banned from selling via Shopify are the following:
                    </p>
                    <ul>
                        <li>
                            <i>Protection of Intellectual Property Compliance.</i> To maintain the integrity of brands and creative works, Shopify restricts the sale of counterfeit products and items that infringe on intellectual property rights. This includes unauthorized use of trademarks, logos, and copyrighted material.
                        </li>
                        <li>
                            <i>Consumer Health and Safety Policy.</i> Products that may pose risks to consumer’s safety or health are typically restricted. Examples include prescription medications, tobacco products, and items making unproven health claims. These restrictions aim to protect consumers from potential harm.
                        </li>
                        <li>
                            <i>Family-Friendly Environment Policy.</i> To create a family-friendly shopping environment and adhere to regulations, adult content, and sexually explicit materials are generally restricted on Shopify.
                        </li>
                        <li>
                            <i>Promotion of Harmful Content.</i> Products that promote hate speech, violence, discrimination, or harm to others are restricted to ensure a safe and inclusive shopping experience for all customers.
                        </li>
                        <li>
                            <i>Intellectual Property Compliance.</i> Products using copyrighted or trademarked material without proper authorization may be restricted to prevent intellectual property violations and legal issues.
                        </li>
                        <li>
                            <i>Highly Regulated Items.</i> Certain items, such as alcohol and firearms, are subject to stringent legal requirements and may be restricted or require special permits to sell. These regulations vary by region and aim to ensure responsible and lawful sales.
                        </li>
                        <li>
                            <i>Hazardous Materials.</i> Products considered hazardous materials, including explosives, flammable chemicals, or radioactive substances, are restricted due to potential safety hazards to customers and the environment.
                        </li>
                        <li>
                            <i>Financial Products Regulation.</i> The sale of financial products like cryptocurrencies or specific investment opportunities may be restricted due to regulatory concerns and to protect consumers from potential scams and fraudulent schemes.
                        </li>
                    </ul>
                    <p>
                        It's important to note that these restrictions may vary depending on the specific terms and conditions of the Shopify platform, as well as the legal and regulatory requirements in different regions. Merchants using Shopify should regularly review the platform's policies and consider consulting legal experts when in doubt about whether their products comply with these restrictions.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/shopify-limitations-for-e-commerce/shopify-limitations-for-e-commerce-img-4.jpg"
                            alt="Article image 4"
                            title="Article image 4"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        When It’s Time To Consider Migration To Another Platform?
                    </h2>
                    <p>
                        Shopify has undoubtedly established itself as a reliable and accessible e-commerce platform, with a wide range of unique features. It's a great option for those who want to start selling products online. However, Shopify is less intuitive and flexible when it comes to your business growth; thus, while scaling up your business activities, you might lose out on your potential customers due to the platform’s existing limitations.
                    </p>
                    <p>
                        For some merchants, migrating from Shopify can be a strategic move in pursuing customizable functionality and tailored support. If you want complete control over your e-commerce store, it’s time to consider other alternatives. While switching your website from Shopify to another platform can seem like a daunting task, with proper management and execution, it will be a secure and successful process.
                    </p>
                    <p>
                        At <b>WDEVS</b>, we use our vast experience ensuring seamless migration from Shopify and preserving your data integrity, comprehensive customization and overall functionality. With the help of our team, you can enjoy the benefits of your new digital platform while minimizing downtime and disruptions to your business operations.
                    </p>
                    <p>
                        Don’t know which e-commerce solution to choose for your business? We offer you a free consultation from our specialists. Our web development experts would be happy to review your current business strategy and platform options to recommend the best choice for your website. <a href="/contacts">Get in touch</a> with us and see how we can help your online brand!
                    </p>
                </div>
                <div>
                    <div class="footer-blog-item">
                        <div
                            class="btn-read-more primary contacts-button small"
                            id="show-modal"
                            @click="this.$router.push('/blog')"
                        >
                            <span class="button-content">
                                <span class="icon">
                                    <img
                                    v-svg-inline
                                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                                    alt="" />
                                </span>
                                Read more our stories
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import Breadcrumbs from "/src/components/Breadcrumbs";
import {mapState} from "vuex";

export default {
    name: "BlogShopifyLimitations",
    data() {
        return {
            backHistory: history.state.back,
            tags:['Shopify','Adobe Commerce','eCommerce','Website Migration','Custom Web Development', 'Shopify Restrictions', 'Online Store', 'Magento Services'],
            scroll: false,
            position: null,
            breadcrumbs: [
                {
                    link: '/',
                    name: 'Home',
                },
                {
                    name: 'Blog',
                    link: '/blog',
                },
                {
                    name: 'Shopify\'s limitations for e-commerce business',
                },
            ],
        };
    },
    components: {
        SidebarBlog,
        Breadcrumbs,
    },
    computed:{
        ...mapState(['blog'])
    },
    methods: {
        goToPath(tag){
            let post = this.blog.find(item => item.tags.includes(tag))
            this.$router.push(post.img_src)
        },
        back(){
            if(!document.referrer || this.backHistory !== '/blog'){
                Object.defineProperty(document, "referrer", {
                    get: function () {
                        return `${window.location.origin}/blog`;
                    },
                });
                this.$router.push('/blog')
                return
            } else{
                this.$router.go(-1)
            }
        },
        scrolling() {
            const scroll = document.documentElement.scrollTop;
            if (scroll > 50) {
                this.scroll = true;
            } else {
                this.scroll = false;
            }
        },
    },
    created() {
        console.log(history.state.back)
        // history.pushState('blog-home',window.location.href)
        window.history.scrollRestoration = "auto";
        window.addEventListener("scroll", this.scrolling);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.scrolling);
    },
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.article-blog-header {
    .article-blog-review__title {
        padding: 120px 0;
        text-align: end;
        text-transform: uppercase;
        color: $black;
        font-size: 32px;
        line-height: 111%;
        margin: 0;
        @include media(">=tablet") {
            font-size: 40px;
            max-width: 100%;
        }
        @include media(">=tablet-xl") {
            font-size: 56px;
        }
        @include media(">=widescreen") {
            font-size: 64px;
            max-width: 100%;
        }
    }
}

.footer-blog-item {
    display: block;
    padding-top: 20px;
    @include media(">=tablet") {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .article-blog-tags {
        margin-top: 40px;
        color: $red;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        @include media(">=tablet") {
            margin-top: 0;
            text-align: center;
        }
        span {
            margin-right: 20px;
            @include media(">=widescreen") {
                margin-right: 40px;
            }
        }
    }
}

.article-blog-page {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .link{
        color:$red;
        cursor:pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .btn-read-more {
        .button-content {
            border-radius: 26px;
            padding: 2px 12px 3px;
            background-color: $red;
            color: $white;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
            width: max-content;
            background-image: linear-gradient($darkRed, $darkRed);
            background-repeat: no-repeat;
            transition: background-size 0s, color 0s;
            background-size: 0 100%;
            @include media(">=tablet-xl") {
                padding: 6px 24px 8px;
            }
            &:hover {
                background-size: 100% 100%;
                color: $white;
            }
        }
        .icon {
            padding-top: 6px;
            margin-right: 15px;
            transform: rotateY(180deg);
        }
        a {
            color: $white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .go-back-to-read {
        padding: 16px 0;
        border-bottom-left-radius: 26px 26px;
        border-bottom-right-radius: 26px 26px;
        position: fixed;
        background: $white;
        z-index: 50;
        width: 100vw;
        transition: all 0.3s;
        @include media(">=tablet-xl") {
            padding: 16px 0;
        }
        &.scrolling {
            padding: 5px 0;
            border-bottom: 1px solid $red;
            @include media(">=tablet-xl") {
                padding: 5px 0;
            }
        }
    }
}
.article-blog {
    &.section_holder {
        display: flex;
        flex-direction: column-reverse;
        @include media(">=tablet-xl") {
            flex-direction: row;
        }
    }
    .section_title {
        margin-right: 0;
        width: 100%;
        @include media(">=tablet-xl") {
            margin-right: 43px;
            width: 35%;
        }
    }
    .section_content {
        margin-bottom: 60px;
        display: block;
        margin-left: 0;
        width: 100%;
        a {
            color: $red;
        }
        &__item {
            margin-bottom: 40px;
            @include media(">=tablet") {
                margin-bottom: 60px;
            }
            @include media(">=desktop") {
                margin-bottom: 120px;
            }
            &-title {
                &-icon {
                    flex-shrink: 0;
                    width: 400px;
                    height: auto;
                    margin-right: 15px;
                    @media all and (max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                    }
                    svg {
                        max-width: 100%;
                        width: 100%;
                        max-height: 100%;
                        height: 100%;
                    }
                }
                &--icon {
                    display: flex;
                    align-items: flex-end;
                    @media all and (max-width: 767px) {
                        flex-wrap: wrap;
                    }
                    @media (min-width: 992px) and (max-width: 1199px) {
                        flex-wrap: wrap;
                    }
                }
            }
            &-slider {
                position: relative;
            }
            &-link {
                display: inline-block;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        @include media(">=tablet-xl") {
            margin-left: 37px;
            width: 65%;
            margin-bottom: 0;
        }
        &__paragraph {
            display: flex;
            margin-bottom: 45px;
            &-icon {
                flex-shrink: 0;
                display: inline-block;
                width: 65px;
                height: 65px;
                margin-right: 10px;
                vertical-align: middle;
                @media all and (min-width: 768px) {
                    width: 100px;
                    height: 100px;
                    margin-right: 15px;
                }
                svg {
                    max-width: 100%;
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                }
            }
        }
        &__list {
            &--two-columns {
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;
            }
            &--two-columns-tablet-desktop {
                @media all and (min-width: 768px) {
                    columns: 2;
                    -webkit-columns: 2;
                    -moz-columns: 2;
                }
            }
        }
    }
}

.article-blog__banner {
    position: relative;
    height: 300px;
    margin-bottom: 60px;
    @include media(">=tablet") {
        height: 500px;
    }
    @include media(">=desktop") {
        height: 600px;
        margin-bottom: 120px;
    }
    &--eclipse {
        z-index: 1;
        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.3);
        }
        .article-blog__banner-title {
            z-index: 3;
        }
    }
    &-img,
    picture {
        display: block;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.article-blog__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
        bottom: 40px;
        font-size: 40px;
        left: 40px;
        max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
        bottom: 80px;
        left: 40px;
        font-size: 56px;
        max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
        bottom: 80px;
        left: 80px;
        font-size: 64px;
        max-width: 1150px;
    }
}
.article-blog__img,
picture {
    margin: 0 0 40px;
    display: block;
}
.article-blog__customer {
    em {
        display: block;
        margin-top: 32px;
    }
    .button {
        margin-top: 40px;
    }
}
</style>
