<template>
  <div @scroll="scrolling()">
    <h1 class="design-title">Design Samples</h1>
    <div class="design-header" :class="[scroll ? 'scrolling' : '']">
      <div class="design-sort">
        <router-link
          v-for="item in designMenu"
          :key="item.name"
          :to="item.path"
          :class="[this.$route.path === item.path ? 'active' : '']"
          >{{ item.name }}</router-link
        >
      </div>
    </div>
    <section class="design-home container">
      <SectionAllProjects v-if="this.$route.path === '/design-samples'" />
      <router-view v-else></router-view>
      <button @click="backToPortfolio()" class="primary button back">
        See more in our portfolio
      </button>
    </section>
  </div>
</template>

<script>
import SectionAllProjects from "../components/DesignSamplesSections/SectionAllProjects.vue";
export default {
  components: {
    SectionAllProjects,
  },
  data() {
    return {
      designMenu: [
        {
          name: "All Projects",
          path: "/design-samples",
        },
        {
          name: "Games",
          path: "/design-samples/games",
        },
        {
          name: "GUI",
          path: "/design-samples/gui",
        },
        {
          name: "Mobile",
          path: "/design-samples/mobile",
        },
        {
          name: "Printed",
          path: "/design-samples/printed",
        },
        {
          name: "Web",
          path: "/design-samples/web",
        },
        {
          name: "Logos",
          path: "/design-samples/logos",
        },
      ],
      history: window.history.state.back,
      scroll: "",
    };
  },
  methods: {
    backToPortfolio() {
      if (window.history.state.back === "/portfolio") {
        this.$router.go(-1);
      } else {
        this.$router.push("/portfolio");
      }
    },
    scrolling() {
      const scroll = document.documentElement.scrollTop;
      if (scroll > 50) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    },
  },
  created() {
    window.history.scrollRestoration = "auto";
    window.addEventListener("scroll", this.scrolling);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.scrolling {
  border-bottom: 1px solid $black;
  position: fixed;
  top: 0;
  z-index: 200;
  transition: all 0.5s ease-in-out;
  &.design-header {
    transition: padding 0.5s ease-in-out;
    padding: 70px 0 10px;
    @include media(">=tablet") {
      padding: 80px 0 20px;
    }
  }
}
.design-header {
  padding-bottom: 20px;
  width: 100vw;
  background: $white;
  @include media(">=tablet") {
    padding-bottom: 80px;
  }
  @include media(">=widescreen") {
    padding-bottom: 100px;
  }
}
.design-home {
  text-align: center;
  .back {
    color: $white;
    padding: 10px 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border-radius: 26px;
    margin-top: 80px;
    @include media(">=desktop") {
      margin-top: 120px;
    }
  }
}
.design-sort {
  text-align: center;
  a {
    text-decoration: none;
    padding: 0;
    color: $black;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    margin: 0 3px;
    &:hover {
      text-decoration: underline;
    }
    &.active {
      color: $black;
      text-decoration: underline;
    }
  }
  @include media(">=tablet") {
    a {
      background: none;
      text-decoration: none;
      border-radius: 26px;
      padding: 6px 12px;
      font-size: 14px;
      margin: 0 10px;
      border: 1px solid $black;
      position: relative;
      display: inline-block;
      color: $black;
      background: $white;
      background-image: linear-gradient($black, $black);
      background-repeat: no-repeat;
      transition: background-size 0.3s, color 0.3s;
      background-size: 0% 100%;
      &:hover {
        background-size: 100% 100%;
        color: $white;
        text-decoration: none;
      }
      &.active {
        color: $white;
        background: $black;
        text-decoration: none;
      }
    }
    //   a{
    //   border:1px solid $black;
    //   border-radius: 26px;
    //   padding: 6px 12px;
    //   font-size: 14px;
    //   margin:0 10px;
    //   &:hover{
    //      color:$white;
    //      background: $black;
    //      text-decoration: none;
    //   }
    //   &.active{
    //     color:$white;
    //     background: $black;
    //     text-decoration: none;
    //   }
    // }
  }
  @include media(">=tablet-xl") {
    a {
      padding: 10px 24px;
      font-size: 16px;
    }
  }
}
.design-title {
  padding: 60px 0 20px;
  text-transform: uppercase;
  color: $black;
  text-align: center;
  line-height: 111%;
  margin: 0;
  font-size: 34px;
  @include media(">=tablet") {
    font-size: 50px;
    padding: 60px 0 40px;
  }
  @include media(">=tablet-xl") {
    font-size: 60px;
  }
  @include media(">=desktop") {
    font-size: 62px;
    padding: 80px 0;
  }
  @include media(">=widescreen") {
    font-size: 64px;
    padding: 120px 0;
  }
  // @include media(">=widescreen-xl") {
  //   font-size: 64px;
  //   padding: 240px 0 220px;
  // }
}
</style>
