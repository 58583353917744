<template>
    <div class="article-blog-page" @scroll="scrolling()">
        <breadcrumbs
            :breadcrumbs="breadcrumbs"
            :scroll="scroll"
            @goBack="back"
        />
        <section class="article-blog__banner article-blog__banner--eclipse">
            <img
                class="article-blog__banner-img"
                src="@/assets/blog/vape-shop-custom-website/vape-shop-custom-website-img-4.jpeg"
                alt="Why your vape shop needs a custom website"
                loading="lazy"
                modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
            <h1 class="article-blog__banner-title">
                Why your vape shop needs a custom website?
            </h1>
        </section>
        <div class="container article-blog section_holder">
            <div class="section_title">
                <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
            </div>
            <div class="section_content">
                <div class="section_content__item">
                    <p>
                        Offline shop owners often think that creating an online presence for their business is a long-term and pretty expensive process. It's close to being true if you have not dealt with e-commerce site creation before. However, suppose you entrust the deal to professionals with 10+ years of experience in various e-commerce site creation and have managed many successful turnkey solutions for the vape industry. In that case, the process of online presence creation is just a deal of a <b>~month of work, and the budget is just around $5K</b> for the starter kit.
                    </p>
                    <p>
                        Get through this article to see how it's easy to set up your online presence when you deal with professionals with wide experience in setting up turnkey solutions within short terms and affordable budgets.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/vape-shop-custom-website.jpg"
                            alt="Article image 1"
                            title="Article image 1"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        ELEVATE YOUR VAPE GAME: WHY YOUR VAPE SHOP NEEDS A CUSTOM WEBSITE
                    </h2>
                    <p>
                        According to recent reports, the global E-cigarette & Vape market size is expected to reach a record USD 46.98 billion by 2030. As a result, competition in the vaping industry is growing rapidly, and every vape shop owner wants to be ahead of his rivals. Simply delivering high-quality vaporizers and accessories isn’t enough to get your company noticed. You need to develop a detailed business plan, stay focused on your goals, and invest in a bulletproof vape marketing strategy.
                    </p>
                    <p>
                        This is why being online is important, especially if you lack the resources to keep the store working efficiently. Web development is an important part of your Vape Shop marketing strategy as your website is oftentimes the first impression for potential clients.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/vape-shop-custom-website/vape-shop-custom-website-img-1.jpeg"
                            alt="Article image 2"
                            title="Article image 2"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        The Challenges
                    </h2>
                    <p>
                        Vape shop owners face unique challenges due to the specifics of their business. They need to navigate the complex terrain of online regulations, which often vary by region and jurisdiction. Additionally, the vape industry constantly evolves with new products, flavors, and technology, making it essential for websites to adapt swiftly to these changes. At the same time, the fierce competition within the industry demands that vape shop websites stand out, both visually and functionally, to capture and retain customers effectively.
                    </p>
                    <p>
                        Vape products often have specific shipping and delivery regulations, including age verification requirements upon delivery. Managing these requirements and ensuring smooth product delivery can be challenging, particularly when dealing with different shipping carriers and regions.
                    </p>
                    <p>
                        Another thing is targeting. For vape stores with physical locations, optimizing for local SEO is vital. Ensuring that the store appears in local search results and maps can be challenging, requiring careful attention to location-specific SEO strategies.
                    </p>
                    <p>
                        Finally, to build and maintain a trusted brand identity, Vape stores often need to provide educational content to inform customers about product usage, safety, and regulations. Crafting informative and engaging content that serves this purpose while complying with legal constraints is the only reasonable way to present yourself as a responsible and reputable business in the digital space.
                    </p>
                </div>
                <div class="section_content__item">
                    <h2 class="section_content__item-title">
                        5 Reasons To Choose an Experienced Custom Web Development Agency:
                    </h2>
                    <ol>
                        <li>
                            <i>Industry Expertise.</i> Vape store web development requires a nuanced understanding of the industry's unique challenges and opportunities. A specialized agency brings industry-specific knowledge, ensuring that the website meets the highest standards and addresses regulatory concerns. At WDEVS, we work closely with the store owners and stakeholders to gain insights into their business goals, target audience, and unique challenges. This understanding forms the foundation of the digital strategy we develop.
                        </li>
                        <li>
                            <i>Customized Solutions.</i> Experienced web development team takes a bespoke approach, tailoring every aspect of the website to the brand's requirements. From design and functionality to payment processing and content management systems, every element is meticulously crafted.
                        </li>
                        <li>
                            <i>Future-proofing.</i> Vape store owners can't afford to constantly overhaul their websites due to rapid industry changes. A skilled development agency can future-proof the site, implementing scalable solutions that adapt to evolving market dynamics.
                        </li>
                        <li>
                            <i>Multichannel Integration.</i> To diversify revenue streams, vape stores may explore various sales channels, such as marketplaces and brick-and-mortar locations. Custom web development agencies play a vital role in integrating these channels into a cohesive strategy. They facilitate the integration of online and offline sales data, inventory management, and customer profiles, allowing vape stores to offer a unified shopping experience across all touchpoints.
                        </li>
                        <li>
                            <i>Data-Driven Decision Making.</i> Data holds the power to transform strategies from good to exceptional. Custom web development teams set up advanced analytics tools, allowing vape stores to gather actionable insights. They track user behavior, monitor sales patterns, and analyze website traffic. By interpreting this data, vape stores can make informed decisions, refine marketing strategies, and personalize user experiences.
                        </li>
                    </ol>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/vape-shop-custom-website/vape-shop-custom-website-img-2.jpeg"
                            alt="Article image 3"
                            title="Article image 3"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        WDEVS Working Strategy For Magento Vape Stores
                    </h2>
                    <p>
                        At WDEVS, we have proved to be experts in effectively collaborating with vape stores worldwide to design & develop websites that are built to handle the thousands of products and variations that they sell.
                    </p>
                    <p>
                        Here's a simplified glimpse into our process for setting up and running a Magento eCommerce-based vape shop:
                    </p>
                    <ul>
                        <li>
                            <i>Understanding Your Needs.</i> We start by diving deep into your business requirements and help you decide between Magento Ecommerce, Commerce, or Commerce Cloud.
                        </li>
                        <li>
                            <i>Setting up the Foundation.</i> We choose the right hosting and set up Magento for your shop. We also give your website a unique look by setting up a theme and uploading your logo.
                        </li>
                        <li>
                            <i>Product Import Made Easy.</i> We streamline the product import process by utilizing Magento's default import system. You can easily import products from a CSV file, which is readily available from most ERP systems and product vendors.
                        </li>
                        <li>
                            <i>Shipping and Payment Convenience.</i> We configure Magento's default shipping methods, including trusted carriers like UPS, USPS, FedEx, and DHL, as well as basic delivery options such as Free Shipping, In-Store Delivery, Flat Rate, and Table rate. Payment methods like PayPal Express Checkout and Braintree are also seamlessly integrated.
                        </li>
                        <li>
                            <i>Ensuring Security.</i> We prioritize your website's security by enabling reCAPTCHA and implementing other necessary security configurations.
                        </li>
                        <li>
                            <i>Optimizing Performance.</i> We enhance your site's performance with features like full-page caching and performance optimization options such as CSS minification, JS merging, and bundling.
                        </li>
                        <li>
                            <i>Server Excellence.</i> We fine-tune your server for optimal performance, including Varnish and Redis configuration.
                        </li>
                        <li>
                            <i>Unlocking Analytics.</i> We integrate Google Analytics and Google Tag Manager to give you insights into your website's traffic and conversions.
                        </li>
                    </ul>
                    <p>
                        This standard process typically takes around <b>120-200 hours</b> (just around $5-10K for a turnkey solution), with flexibility for customization. For example, if you want specific homepage sliders or infinite scrolling on catalog pages, we can accommodate these enhancements with a minimal budget increase.
                    </p>
                    <p>
                        We're ready to tailor every aspect to your unique needs, whether it's customizing your website's theme or implementing specific payment and shipping methods beyond Magento's defaults.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/vape-shop-custom-website/vape-shop-custom-website-img-3.jpg"
                            alt="Article image 4"
                            title="Article image 4"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        Why Magento?
                    </h2>
                    <p>
                        What makes Magento truly exceptional is its unparalleled versatility. It's not just an e-commerce platform; it's a solution that offers a world of customization possibilities, both on the front end and back end. Unlike other popular e-commerce systems like Shopify and BigCommerce, Magento knows no bounds.
                    </p>
                    <p>
                        At WDEVS, we've been working with numerous vape store clients; many of them operate brick-and-mortar vape shops and already have existing systems in place. Take, for instance, one of our clients who managed products through a local ERP system, handled customer relationships with Zoho, and managed their finances using QuickBooks. When we introduced Magento as a new sales channel for them, we didn't just stop there; we seamlessly integrated Magento with their existing systems.
                    </p>
                    <p>
                        Here's how we made it happen:
                    </p>
                    <ul>
                        <li>
                            Product Integration: We ensured that the products were automatically exported from their local system to Magento.
                        </li>
                        <li>
                            Customer Data Synchronization: Customer data, a crucial asset, was seamlessly exported from Magento to Zoho, keeping their customer relationship management up to date.
                        </li>
                        <li>
                            Financial Integration: Sales data, including orders and invoices, was automatically exported to QuickBooks, streamlining their financial management.
                        </li>
                    </ul>
                    <p>
                        It doesn’t matter whether you use a single ERP system into which all the data is integrated, or several different systems (for example, if you have CRM in one and Sales Management in the other), we will offer the most optimal Magento store solution for you, taking into account all software you use. We know how to develop a Magento store that suits all your business needs and technical requirements.
                    </p>
                    <p>
                        The complexity of each integration depends on our client's unique business needs and the availability of ready-made solutions. Like, QuickBooks integration was made easier thanks to a variety of extensions available:
                    </p>
                    <p>
                        <a href="https://commercemarketplace.adobe.com/catalogsearch/result/?q=quickbooks.">QuickBooks Extensions</a>
                    </p>
                    <p>
                        In some cases, for systems like NetSuite or Salesforce, we develop our own custom solutions tailored specifically for our clients.
                    </p>
                    <p>
                        But it's not just about finding the right tools; technical nuances play a pivotal role in successful integrations. Here are just some of those:
                    </p>
                    <ul>
                        <li>
                            <i>One-Way vs. Two-Way Synchronization.</i> Depending on the needs, we can implement one-way or two-way synchronization. One-way is a one-sided data transfer, while two-way ensures data accuracy by importing and exporting data between systems.
                        </li>
                        <li>
                            <i>Data Volume.</i> The amount of data to be synchronized can impact implementation time. More data requires meticulous mapping and configuration, which can extend the project timeline.
                        </li>
                        <li>
                            <i>Custom Solutions.</i> In some cases, we develop entirely bespoke solutions based on ERP documentation to meet our client's unique business strategy.
                        </li>
                    </ul>
                    <p>
                        Whether you need a straightforward one-way sync or a highly customized solution, we've got you covered. WDEVS offers you the development of a modern and well-functioning Magento store, which can become a successful starting point of your business in the vaping industry (if you just plan to enter the market), or yet another effective sales channel if you already have a developed network of offline stores and warehouses. In this case, we will analyze what you are already using and ensure that Magento is integrated into your brand’s business logic as efficiently as possible.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/vape-shop-custom-website/vape-shop-custom-website-img-4.jpeg"
                            alt="Article image 5"
                            title="Article image 5"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        But Is It Time/Cost-Effective?
                    </h2>
                    <p>
                        As a web development agency specializing in e-commerce, we recognize that every vape shop has a unique business strategy. Whether you’re a leading player in this industry or a growing startup with a focus on cost optimization, we know how to help you streamline your business operations and attract new customers.
                    </p>
                    <p>
                        That's why we offer two distinct approaches to cater to the diverse needs of vape businesses.
                    </p>
                    <h3 class="section_content__item-title">
                        1. Custom-Tailored Website Development
                    </h3>
                    <p>
                        For vape stores with specific and intricate requirements, our team of experts is here to craft an online platform that perfectly aligns with your vision. This approach involves a comprehensive analysis of your business needs and objectives, resulting in a tailor-made solution that sets you apart from the competition.
                    </p>
                    <p>
                        Full customization has distinctive benefits that you may not find in a standard package:
                    </p>
                    <ul>
                        <li>
                            <i>Tailored to Your Vision.</i> We start by thoroughly understanding your business goals, brand identity, and customer base. With this knowledge, we craft a website that aligns perfectly with your vision.
                        </li>
                        <li>
                            <i>Personalization & Customization.</i> You have complete control over every aspect of your website. From the design and layout to functionality and user experience, our team will build a solution that's truly yours.
                        </li>
                        <li>
                            <i>Scalability.</i> Our custom-tailored websites are built to grow with your business. As your vape store expands, your website can seamlessly accommodate new features, products, and customer demands.
                        </li>
                        <li>
                            <i>Optimized Performance.</i> Our development process prioritizes performance optimization, ensuring fast loading times, smooth navigation, and a user-friendly experience for your customers.
                        </li>
                        <li>
                            <i>Security and Reliability.</i> We implement thorough security measures to protect your website and customer data. You can trust that your online store is safe from potential threats.
                        </li>
                    </ul>
                    <p>
                        If you’re considering different options and need more detailed information, our team is here to help. We are ready to offer you a free consultation to discuss your specific needs, goals, and budget. <a href="/contacts">Contact us</a> to schedule a call or an online meeting as you prefer!
                    </p>
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/vape-shop-custom-website/vape-shop-custom-website-img-5.jpg"
                            alt="Article image 6"
                            title="Article image 6"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h3 class="section_content__item-title">
                        2. The Quick and Easy Way
                    </h3>
                    <p>
                        Surely, not every vape store needs a highly customized solution from the get-go. That's why we've designed a streamlined approach that allows you to get your vape store up and running within two weeks – and without breaking the bank.
                    </p>
                    <p>
                        Take, for example, <a href="https://shalisto.com/">Shalisto</a>, a vape store that came to us through a recommendation from one of our clients, a large US-based Vape network. They had a product catalog in XLS format and a desire to be online quickly. We transformed their vision into reality in record time, completing their online store in less than 100 hours. What’s worth mentioning, 30 of those hours were spent on importing products from their XLS file.
                    </p>
                    <p>
                        <i>Here's a more detailed breakdown of how we can make this happen for you:</i>
                    </p>
                    <p>
                        <b>✅ Product Setup.</b> If you have a limited number of products and wish to save on development costs, you can set up your products yourself using the intuitive Magento backend. We'll provide guidance on how to manage it effectively.
                    </p>
                    <p>
                        <b>✅ Theme Selection.</b> To keep costs in check, Shalisto opted for a ready-to-use Magento theme. There's a wide selection of free and paid themes available in the market, and we can help you choose one that suits your brand.
                    </p>
                    <p>
                        <b>✅ Customization.</b> Once the theme was selected, we customized it to match Shalisto's desired design style. All they provided to us were their logo and a color scheme.
                    </p>
                    <p>
                        <b>✅ Payment and Shipping.</b> We set up payment gateways and shipping extensions, enabling them to accept online payments and ship their products worldwide. With this in place, their site was ready to start generating revenue.
                    </p>
                    <p>
                        <b>✅ Advanced Features.</b> While the initial setup was quick, Shalisto later wanted to add more complex product configurations for selling bundles and pre-ordering products. This additional work took an extra 10-20 hours.
                    </p>
                    <p>
                        Whether you're seeking a comprehensive, custom-tailored solution or a quick and cost-effective way to get started, we've got the expertise and flexibility to meet your unique needs. Your success in the vape industry is our priority, and we're here to help you achieve it. <b>Want to get started on your next project or need some more information before you dive in? <a href="/contacts">Get in touch.</a></b>
                    </p>
                </div>
                <div>
                    <div class="footer-blog-item">
                        <div
                            class="btn-read-more primary contacts-button small"
                            id="show-modal"
                            @click="this.$router.push('/blog')"
                        >
                            <span class="button-content">
                                <span class="icon">
                                    <img
                                        v-svg-inline
                                        :data-src="require('@/assets/icons/rarr-icon.svg')"
                                        alt="" />
                                </span>
                                Read more our stories
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import Breadcrumbs from "/src/components/Breadcrumbs";
import {mapState} from "vuex";

export default {
    name: "BlogVapeShopCustomWebsite",
    data() {
        return {
            backHistory: history.state.back,
            tags: ['Magento', 'eCommerce', 'Vape Store', 'Custom Web Development', 'Vape Shop', 'Web Design', 'Magento Services'],
            scroll: false,
            position: null,
            breadcrumbs: [
                {
                    link: '/',
                    name: 'Home',
                },
                {
                    name: 'Blog',
                    link: '/blog',
                },
                {
                    name: 'Why your vape shop needs a custom website?',
                },
            ],
        };
    },
    components: {
        SidebarBlog,
        Breadcrumbs,
    },
    computed:{
        ...mapState(['blog'])
    },
    methods: {
        goToPath(tag){
            let post = this.blog.find(item => item.tags.includes(tag))
            this.$router.push(post.img_src)
        },
        back(){
            if(!document.referrer || this.backHistory !== '/blog'){
                Object.defineProperty(document, "referrer", {
                    get: function () {
                        return `${window.location.origin}/blog`;
                    },
                });
                this.$router.push('/blog')
                return
            } else{
                this.$router.go(-1)
            }
        },
        scrolling() {
            const scroll = document.documentElement.scrollTop;
            if (scroll > 50) {
                this.scroll = true;
            } else {
                this.scroll = false;
            }
        },
    },
    created() {
        console.log(history.state.back)
        // history.pushState('blog-home',window.location.href)
        window.history.scrollRestoration = "auto";
        window.addEventListener("scroll", this.scrolling);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.scrolling);
    },
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.article-blog-header {
    .article-blog-review__title {
        padding: 120px 0;
        text-align: end;
        text-transform: uppercase;
        color: $black;
        font-size: 32px;
        line-height: 111%;
        margin: 0;
        @include media(">=tablet") {
            font-size: 40px;
            max-width: 100%;
        }
        @include media(">=tablet-xl") {
            font-size: 56px;
        }
        @include media(">=widescreen") {
            font-size: 64px;
            max-width: 100%;
        }
    }
}

.footer-blog-item {
    display: block;
    padding-top: 20px;
    @include media(">=tablet") {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .article-blog-tags {
        margin-top: 40px;
        color: $red;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        @include media(">=tablet") {
            margin-top: 0;
            text-align: center;
        }
        span {
            margin-right: 20px;
            @include media(">=widescreen") {
                margin-right: 40px;
            }
        }
    }
}

.article-blog-page {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .link{
        color:$red;
        cursor:pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .btn-read-more {
        .button-content {
            border-radius: 26px;
            padding: 2px 12px 3px;
            background-color: $red;
            color: $white;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
            width: max-content;
            background-image: linear-gradient($darkRed, $darkRed);
            background-repeat: no-repeat;
            transition: background-size 0s, color 0s;
            background-size: 0 100%;
            @include media(">=tablet-xl") {
                padding: 6px 24px 8px;
            }
            &:hover {
                background-size: 100% 100%;
                color: $white;
            }
        }
        .icon {
            padding-top: 6px;
            margin-right: 15px;
            transform: rotateY(180deg);
        }
        a {
            color: $white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .go-back-to-read {
        padding: 16px 0;
        border-bottom-left-radius: 26px 26px;
        border-bottom-right-radius: 26px 26px;
        position: fixed;
        background: $white;
        z-index: 50;
        width: 100vw;
        transition: all 0.3s;
        @include media(">=tablet-xl") {
            padding: 16px 0;
        }
        &.scrolling {
            padding: 5px 0;
            border-bottom: 1px solid $red;
            @include media(">=tablet-xl") {
                padding: 5px 0;
            }
        }
    }
}
.article-blog {
    &.section_holder {
        display: flex;
        flex-direction: column-reverse;
        @include media(">=tablet-xl") {
            flex-direction: row;
        }
    }
    .section_title {
        margin-right: 0;
        width: 100%;
        @include media(">=tablet-xl") {
            margin-right: 43px;
            width: 35%;
        }
    }
    .section_content {
        margin-bottom: 60px;
        display: block;
        margin-left: 0;
        width: 100%;
        a {
            color: $red;
        }
        &__item {
            margin-bottom: 40px;
            @include media(">=tablet") {
                margin-bottom: 60px;
            }
            @include media(">=desktop") {
                margin-bottom: 120px;
            }
            &-title {
                &-icon {
                    flex-shrink: 0;
                    width: 400px;
                    height: auto;
                    margin-right: 15px;
                    @media all and (max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                    }
                    svg {
                        max-width: 100%;
                        width: 100%;
                        max-height: 100%;
                        height: 100%;
                    }
                }
                &--icon {
                    display: flex;
                    align-items: flex-end;
                    @media all and (max-width: 767px) {
                        flex-wrap: wrap;
                    }
                    @media (min-width: 992px) and (max-width: 1199px) {
                        flex-wrap: wrap;
                    }
                }
            }
            &-slider {
                position: relative;
            }
            &-link {
                display: inline-block;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        @include media(">=tablet-xl") {
            margin-left: 37px;
            width: 65%;
            margin-bottom: 0;
        }
        &__paragraph {
            display: flex;
            margin-bottom: 45px;
            &-icon {
                flex-shrink: 0;
                display: inline-block;
                width: 65px;
                height: 65px;
                margin-right: 10px;
                vertical-align: middle;
                @media all and (min-width: 768px) {
                    width: 100px;
                    height: 100px;
                    margin-right: 15px;
                }
                svg {
                    max-width: 100%;
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.article-blog__banner {
    position: relative;
    height: 300px;
    margin-bottom: 60px;
    @include media(">=tablet") {
        height: 500px;
    }
    @include media(">=desktop") {
        height: 600px;
        margin-bottom: 120px;
    }
    &--eclipse {
        z-index: 1;
        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.3);
        }
        .article-blog__banner-title {
            z-index: 3;
        }
    }
    &-img,
    picture {
        display: block;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.article-blog__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
        bottom: 40px;
        font-size: 40px;
        left: 40px;
        max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
        bottom: 80px;
        left: 40px;
        font-size: 56px;
        max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
        bottom: 80px;
        left: 80px;
        font-size: 64px;
        max-width: 1150px;
    }
}
.article-blog__img,
picture {
    margin: 0 0 40px;
    display: block;
}
.article-blog__customer {
    em {
        display: block;
        margin-top: 32px;
    }
    .button {
        margin-top: 40px;
    }
}
</style>
