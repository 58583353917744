<template>
  <section class="mfo__banner">
    <picture class="mfo__banner-img">
      <source
          media="(max-width: 768px)"
          srcset="
            @/assets/magento/magento-version-switching/magento-version-switching_small.webp    1x,
            @/assets/magento/magento-version-switching/magento-version-switching_small@2x.webp 2x
          "
      />
      <source
          media="(max-width: 768px)"
          srcset="
            @/assets/magento/magento-version-switching/magento-version-switching_small.jpg    1x,
            @/assets/magento/magento-version-switching/magento-version-switching_small@2x.jpg 2x
          "
      />

      <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcset="
            @/assets/magento/magento-version-switching/magento-version-switching_medium.webp    1x,
            @/assets/magento/magento-version-switching/magento-version-switching_medium@2x.webp 2x
          "
      />
      <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcset="
            @/assets/magento/magento-version-switching/magento-version-switching_medium.jpg    1x,
            @/assets/magento/magento-version-switching/magento-version-switching_medium@2x.jpg 2x
          "
      />

      <source
          srcset="
            @/assets/magento/magento-version-switching/magento-version-switching.webp    1x,
            @/assets/magento/magento-version-switching/magento-version-switching@2x.webp 2x
          "
      />
      <source
          srcset="
            @/assets/magento/magento-version-switching/magento-version-switching.jpg    1x,
            @/assets/magento/magento-version-switching/magento-version-switching@2x.jpg 2x
          "
      />

      <img
          src="@/assets/magento/magento-version-switching/magento-version-switching.jpg"
          alt="Switching from Magento 1x to Magento 2x"
          loading="lazy"
          width="1920"
          height="640"
      />
    </picture>
    <h1 class="mfo__banner-title">
      Switching from Magento 1x to Magento 2x
    </h1>
  </section>

  <section-layout name="mfo__first" title="" margin="true">
    <p>Magento 2 offers its clients a modern, user-friendly, responsive design, optimized performance & enhanced security;  as well as dynamic, cloud-based architecture, better customer experience and so much more.</p>
    <p><b>Why Migrate from Magento 1 to Magento 2?</b></p>
  </section-layout>

  <section-layout name="mfo__advanced" title="Advanced performance" margin="true">
    <p>Magento 2 provides faster performance and offers; on  average, a 50% faster page loading speed when compared to Magento 1. Your users won’t need to wait forever to load all  the page elements, and the checkout page is also improved, thereby processing the data on the page 38% faster than Magento 1!</p>
  </section-layout>

  <section-layout name="mfo__architecture" title="Customizable website architecture" margin="true">
    <p>Thanks to its optimized structure and customizable admin panel, Magento 2 successfully manages all your eCommerce activities. It provides comprehensive support for the most popular dev tools like Apache, NGINX, MySQL, PHP, Symfony, as well as many others, and as a result, you always achieve better code performance.</p>
  </section-layout>

  <section-layout name="mfo__ui-ux" title="Enhanced UX/UI opportunities" margin="true">
    <p>Magento 2 is a completely new platform that has been developed using totally different technology to M1, and therefore offers advanced features and a modern backend UX. This leads to more returning customers on your website, and positive feedback. The dashboard in the admin panel is easy to use; allowing you to choose the primary functions, as well as configure & customize product display to ensure your customers swiftly find the necessary information on the page.</p>
  </section-layout>

  <section-layout name="mfo__improved-shopping" title="Improved Shopping Experience" margin="true">
    <p>Magento 2 offers a significantly improved shopping experience for your customers. All of this is possible thanks to the simpler checkout process, faster response time & custom design of your home & product pages. A  responsive design is another benefit that you can look forward to, as it attracts more visitors on all devices.</p>
  </section-layout>

  <section-layout name="mfo__improved-security" title="Improved security & support" margin="true">
    <p>Magento 2 provides improved website security, as well as safer customer data storage - which  means a great deal to website users who are concerned about the confidentiality of their personal information. Magento 2 offers various kinds of protection, such as Google reCAPTCHA, secondary password prompts, PCI Data Security and more, to ensure a secure shopping process.</p>
    <p>Besides all these previously mentioned pros in favor of Magento 2, they also constantly release advanced security patches to keep your website safe.</p>
  </section-layout>

  <section-layout name="mfo__migration-steps" title="M1-M2 Migration Steps" margin="true">
    <ul>
      <li>Analyzing your Magento 1 website</li>
      <li>Creating the migration plan</li>
      <li>Developing a Magento 2 environment</li>
      <li>Data & Media Migration to M2</li>
      <li>Theme & 3rd Party Extensions Setup</li>
      <li>Customization</li>
      <li>Testing & Bug Fixing</li>
      <li>Website Launch</li>
    </ul>
    <p><b>Important:</b> We implement the migration tasks while your old website keeps working, enabling you to carry out your sales plan in the usual manner.  Transferring the data from the M1 version to your new M2 website is just one of the building elements which is a part of our scope of services.</p>
    <p>Magento 2 migration is a complex multi-stage process, therefore we recommend reaching out to a  professional Magento agency, with certified Magento developers & a proven track record of  similar projects.</p>
    <p>The WDevs team has already earned the trust of our well-respected clients from various eCommerce business sectors. Constantly keeping up with deadlines and completing tasks on time, as well as on budget; our Magento experts can guarantee your webstore site’s smooth M2 migration, by offering the most efficient solution for your business.</p>
    <a href="#" @click.prevent="goBack()" class="button primary back">Back to Magento</a>
  </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";

export default {
  data(){
    return{
      position:null,
    }
  },
  components: {
    SectionLayout,
  },
  methods:{
    goBack(){
      if(!document.referrer){
        Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/magento` }})
        this.$router.push('/magento')
      }else{
        this.$router.go(-1)
      }
    }
  },
  created(){
    window.history.scrollRestoration = "auto";
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.mfo {
  &__banner {
    height: 300px;
    position: relative;
    @include media(">=tablet") {
      height: 500px;
    }
    @include media(">=desktop") {
      height: 640px;
      margin-bottom: 60px;
    }
    &-img {
      display: block;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
      bottom: 40px;
      font-size: 40px;
      left: 40px;
      max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
      bottom: 80px;
      left: 40px;
      font-size: 56px;
      max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
      bottom: 80px;
      left: 80px;
      font-size: 64px;
      max-width: 1150px;
    }
  }

  &__first,
  &__advanced,
  &__architecture,
  &__ui-ux,
  &__improved-shopping,
  &__improved-security,
  &__migration-steps {
    .section_content {
      display: block;
    }
    a {
      color: $red;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .button{
      cursor: pointer;
      margin-top:40px;
      color:$white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      padding: 10px 24px;
      border-radius:26px;
      &:hover{
        text-decoration: none;
      }
    }
    ul{
      li{
        margin-bottom:5px;
      }
    }
  }
}

</style>
