<template>
  <div class="security-audit" @scroll="scrolling()">
    <breadcrumbs :breadcrumbs="breadcrumbs" :scroll="scroll" @goBack="back" />
    <section class="security-audit__banner">
        <img
            class="security-audit__banner-img"
            src="@/assets/blog/development-during-recession/development-during-recession-top.jpg"
            alt="Outsourcing web development during a recession"
            loading="lazy"
            modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
        />
      <h1 class="security-audit__banner-title">
        HOW TO KEEP AFLOAT BY OUTSOURCING WEB DEVELOPMENT DURING A RECESSION
      </h1>
    </section>
    <div class="container blog-security-audit section_holder">
      <div class="section_title">
        <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
      </div>
      <div class="section_content">
        <div class="section_content__item">
          <p>
            Many signs are currently indicating that the global economy is heading towards a recession. According to a <a href="https://www.worldbank.org/en/news/press-release/2022/09/15/risk-of-global-recession-in-2023-rises-amid-simultaneous-rate-hikes" target="_blank">World Bank report</a>, economic growth is expected to slow down in 2023 - even more drastically than was previously expected, because the ongoing war in Ukraine has negatively affected prospects for a post-COVID economic recovery, especially in Europe and North America.
          </p>
          <p>
            It is therefore imperative for e-commerce businesses to remain competitive, that’s why they need to invest in technology. At the same time, we must consider that the IT labor market may still <a href="https://www.computerweekly.com/news/252522740/Global-IT-services-market-shows-signs-of-slowing" target="_blank">experience</a> some challenging times right now as more and more developers expect to be paid more to maintain the same quality of life; especially given the fact that the cost of living is rising along with inflation. As a result, this drove  many small and medium-sized  companies to spend more on web development and IT services throughout 2022 and into 2023, than was originally anticipated.
          </p>
          <p>
            If you are the owner of a growing e-commerce business, the issue of optimizing IT support & web development costs is now particularly acute. So, how to achieve this without loss of business development, and still have the opportunity to profit from sales through your online platform?
          </p>
          <p>
            If a volatile economy forces you to be flexible, outsourcing is your solution to manage budget reconfiguration and develop more adaptable expenses
          </p>
          <h2 class="section_content__item-title">Why is outsourcing a good move for your business now?</h2>
          <p>
            If you are the owner of a growing business, obviously your first concern during a recession,would be to consider the additional outsourcing costs. But will it really be an additional cost?
          </p>
          <p>
            The thing is, outsourcing is a capital expense. A dedicated turn-key, outsourced customer-focused team does not bring you added operating expenses, nor will  it provide any extra work for your existing in-house team. There are many benefits to outsourcing, especially when facing economic turmoil. A well-chosen outsourcing partner can help your business reduce costs, improve efficiency, and provide  your team with additional expertise. Let's take a more indepth look:
          </p>
          <ul>
            <li><strong>Improved economic efficiency</strong></li>
          </ul>
          <p>
            The minimum rate of a middle developer in the US is above <b>$100</b> per hour. We should also take into consideration  that the cost of living in different  areas can vary based on factors such as job position, average salary and the real estate market in that area. So, the rate for a professional in-house developer can be even higher. Add rising inflation rates and social benefits to that, and you will see that the final workforce cost skyrockets. Another issue is the retention rate considering those employees who are essential to your business. Outsourcing can help you shift a portion of the work that used to be done by your in-house team, thus increasing their productivity and allowing them to fully focus on core tasks.
          </p>
          <p>
            Our experienced developer rates run from <b>$40</b> per hour. Compare that with the time and money it takes to find and train a new employee - and it’s obviously  a quicker and more efficient solution for your tasks. Our specialists work remotely all over the world, therefore we are always ready to offer you a professional developer, who will understand the specifics of your project. Time-synced, with all-round support, WDEVS pros are a shining example of what you look for when you’re looking for an experienced outsource team.
          </p>
          <p>
            Collaborating with WDEVS means that you have direct access to an international team of experts, with a collective pool of more than 16 years of intense research and innovative experience. All this knowledge without needing to pay for staff training or additional office space ever again, no more reason to ever micromanage an overcrowded office again, and last but not least - only pay one company, instead of various individuals… and all the paperwork which that entails.
          </p>
          <ul>
            <li><strong>Reduced operational costs</strong></li>
          </ul>
          <p>
            During a recession, every company’s top priority is cost reduction. If you partner with an outsourcing provider, you save money on tasks that do not require a full-time, higher-level employee, therefore you will be able to prioritize your goals, only maintain the employees that are most vital to your business, and most importantly - keep them occupied with essential work.
          </p>
          <p>
            Whether your organization is a growing start-up or a major, well-established company, delegating IT tasks to an outsource provider can be an excellent way to lower your operational costs and increase efficiency since you will be paying one flat-rate fee to the outsourcing company, rather than paying each staff member.
          </p>
          <p>
            During a recession, another  painful point for any employer with an in-house web dev team, is cutting social benefits. These can be crossed off as a result of radical cost-cutting measures to save your company’s financial stability - however, if you outsource specific departments such as IT, you won’t have to worry about leaving your devoted in-house team members dissatisfied with their current workplace situation. Full scope of services
          </p>
          <p>
            Technology is continually changing and improving, so, if you get accustomed  to maintaining an exclusively in-house development team, you must ensure they dedicate a significant part of their time to keeping up with the tendencies and trends in the industry.
          </p>
          <p>
            Experienced outsource web dev companies like WDEVS have already been doing the research so as to understand how new technologies and approaches can benefit your business. You have the opportunity to take advantage of our comprehensive expertise without needing to invest the time or money necessary to discover these new technological developments firsthand.
          </p>
          <p>
            Furthermore, expert developers always know what stack of technologies will be the best choice for your particular project. They provisionally eliminate all unnecessary tools and help you avoid additional long-term expenses. Plus, outsourced IT pros can bring valuable ideas to the table during your web development process and custom-tailor the process to your specific needs - which is an ultra-efficient method to maintain  your business goals during these difficult times.
          </p>
          <p>
            We provide a full range of services, and if the developer requires the assistance of one of our DevOps - immediate help is on-hand. We also take care of day-to-day tasks, such as installing software, administering backups, security tasks, network administration, maintenance, upgrades, and technology management.
          </p>
          <p>
            We’ve collectively spent large sums of money, as well as dedicated countless hours to develop the best practices and customized web dev tools, so that you could rest assured that you won’t ever have to again.
          </p>
          <ul>
            <li><strong>Reduced Development Time</strong></li>
          </ul>
          <p>
            Time is always a matter of high priority when we speak about web development. The outsource company is able to quickly allocate necessary resources for your project and provide the desired results on deadline.
          </p>
          <p>
            Flexibility is another advantage – whether your priority is to access the collective knowledge of our team,or you simply just need to downsize your project team, our outsource web developers will follow your instructions and provide you with what your company needs without any negative effects to your project delivery. We can help you achieve your goals faster.
          </p>
          <p>
            Some factors can affect the time it takes to complete tasks - such as high staff turnover and continuously investing resources in training new employees, as well as working around sick leave and vacations. Once you partner with WDEVS, you will be working with a team that is highly competent in the exact field of expertise, which your company needs - a team that is accustomed to receiving diverse requests, working on tight schedules, and delivering on time. A team that knows what they’re doing and doesn’t need you to micro-manage them, leaving you with more time to develop and grow your business during these trying times.
          </p>
          <p>
            With an outsource team such as ours - you can rest assured that your product is always delivered to your customers on time and within budget.
          </p>
          <h3>
            Last But Not Least…
            <picture>
              <img
                src="@/assets/blog/development-during-recession/ukraine.png"
                alt="Ukraine flag"
                loading="lazy"
                height="36"
                width="36"
              />
            </picture>
          </h3>
          <p>
            Our core team is Ukrainian. The war affected many areas of business, including the IT market. Most of our employees had to relocate to other countries in order to continue working and ensure the safety of their families.
          </p>
          <p>
            Developers from Ukraine are globally known for their dedication and hard work, and are also constantly striving to be among the best in their industry. We send part of our revenues to humanitarian trusts in Ukraine to help those who are still suffering due to this terrible war.
          </p>
          <p>
            Collaborating with WDEVS, means you are supporting Ukraine in the fight for freedom, as well as the right for all Ukrainians to be involved in fully-fledged, active international markets.
          </p>
          <p>
            <strong>WDEVS has successfully been providing custom-oriented outsourced solutions worldwide for 16+ years. You can view our <a href="/portfolio">portfolio</a> and some of our <a href="/blog">most successful projects</a> to see why outsourcing can be a working solution for your business.</strong>
          </p>
          <p>
            <strong>Don’t hesitate to contact us - let’s discuss your project!</strong>
          </p>
        </div>


        <div>
          <div class="footer-blog-item">
            <div
                class="btn-read-more primary contacts-button small"
                id="show-modal"
                @click="this.$router.push('/blog')"
            >
              <span class="button-content">
                <span class="icon"
                ><img
                    v-svg-inline
                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                    alt=""
                /></span>
                Read more our stories
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import { mapState } from "vuex"
import Breadcrumbs from "/src/components/Breadcrumbs";

export default {
  name: "BlogOutsourcingWebDevelopment",
  data() {
    return {
      backHistory:history.state.back,
      showModal:false,
      tags:['Magento Outsource', 'eCommerce', 'Custom Web Development', 'Outsourcing', 'Magento Services'],
      scroll: false,
      position: null,
      text: `
      Would you like to receive more examples of what we are able to help you achieve?<br />
      Do you still have doubts about your idea and believe that it will be too hard to implement?<br/>
      Would you like us to propose ways of turning your ideas into reality?
      `,
      breadcrumbs: [
        {
          link: '/',
          name: 'Home',
        },
        {
          name: 'Blog',
        },
        {
          name: 'Outsourcing Web Development During a Recession',
        },
      ],
    };
  },
  components: {
    SidebarBlog,
    Breadcrumbs,
  },
  computed:{
    ...mapState(['blog'])
  },
  methods: {
    goToPath(tag){
      let post = this.blog.find(item => item.tags.includes(tag))
      this.$router.push(post.img_src)
    },
    back(){
      if(!document.referrer || this.backHistory !== '/blog'){
        Object.defineProperty(document, "referrer", {
          get: function () {
            return `${window.location.origin}/blog`;
          },
        });
        this.$router.push('/blog')
        return
      } else{
        this.$router.go(-1)
      }
    },
    scrolling() {
      const scroll = document.documentElement.scrollTop;
      if (scroll > 50) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    },
  },
  created() {
    console.log(history.state.back)
    window.history.scrollRestoration = "auto";
    window.addEventListener("scroll", this.scrolling);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  },

};
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.security-audit-header {
  .security-audit__title {
    padding: 120px 0;
    text-transform: uppercase;
    text-align: end;
    color: $black;
    font-size: 32px;
    line-height: 111%;
    margin: 0;
    @include media(">=tablet") {
      font-size: 40px;
      max-width: 100%;
    }
    @include media(">=tablet-xl") {
      font-size: 56px;
    }
    @include media(">=widescreen") {
      font-size: 64px;
      max-width: 100%;
    }
  }
}
.footer-blog-item {
  display: block;
  padding-top: 20px;
  @include media(">=tablet") {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .epr-tags {
    margin-top: 40px;
    color: $red;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    @include media(">=tablet") {
      margin-top: 0;
      text-align: center;
    }
    span {
      margin-right: 20px;
      @include media(">=widescreen") {
        margin-right: 40px;
      }
    }
  }
}

.security-audit {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  a.outside_link {
    color: $black !important;
    font-style: italic;
  }
  .btn-read-more {
    .button-content {
      border-radius: 26px;
      padding: 2px 12px 3px;
      background: $red;
      color: $white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      width: max-content;
      background-image: linear-gradient($darkRed, $darkRed);
      background-repeat: no-repeat;
      transition: background-size 0s, color 0s;
      background-size: 0% 100%;
      @include media(">=tablet-xl") {
        padding: 6px 24px 8px;
      }
      &:hover {
        background-size: 100% 100%;
        color: $white;
      }
    }
    .icon {
      padding-top: 6px;
      margin-right: 15px;
      transform: rotateY(180deg);
    }
    a {
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .go-back-to-read {
    padding: 16px 0;
    border-bottom-left-radius: 26px 26px;
    border-bottom-right-radius: 26px 26px;
    position: fixed;
    background: $white;
    z-index: 50;
    width: 100vw;
    transition: all 0.3s;
    @include media(">=tablet-xl") {
      padding: 16px 0;
    }
    &.scrolling {
      padding: 5px 0;
      border-bottom: 1px solid $red;
      @include media(">=tablet-xl") {
        padding: 5px 0;
      }
    }
  }
}
.blog-security-audit {
  &.section_holder {
    display: flex;
    flex-direction: column-reverse;
    @include media(">=tablet-xl") {
      flex-direction: row;
    }
  }
  .section_title {
    width: 100%;
    margin-right: 0;
    @include media(">=tablet-xl") {
      margin-right: 43px;
      width: 35%;
    }
  }
  .section_content {
    margin-bottom: 60px;
    display: block;
    margin-left: 0;
    width: 100%;
    a {
      color: $red;
    }
    &__item {
      h3{
        font-size: 20px;
      }
      margin-bottom: 40px;
      @include media(">=tablet") {
        margin-bottom: 60px;
      }
      @include media(">=desktop") {
        margin-bottom: 120px;
      }
      &-title {
        font-size: 24px;
      }
    }
    @include media(">=tablet-xl") {
      margin-left: 37px;
      width: 65%;
      margin-bottom: 0;
    }
  }
}

.security-audit__banner {
  height: 300px;
  position: relative;
  margin-bottom: 60px;
  overflow: hidden;
  @include media(">=tablet") {
    height: 500px;
  }
  @include media(">=desktop") {
    height: 600px;
    margin-bottom: 120px;
  }
  &-img,
  picture {
    display: block;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.security-audit__banner-title {
  max-width: calc(100% - 32px);
  text-transform: uppercase;
  color: $white;
  font-size: 32px;
  line-height: 111%;
  position: absolute;
  bottom: 20px;
  left: 16px;
  margin: 0;
  @include media(">=tablet") {
    bottom: 40px;
    font-size: 40px;
    left: 40px;
    max-width: calc(100% - 80px);
  }
  @include media(">=tablet-xl") {
    bottom: 80px;
    left: 40px;
    font-size: 56px;
    max-width: calc(100% - 80px);
  }
  @include media(">=widescreen") {
    bottom: 80px;
    left: 80px;
    font-size: 64px;
    max-width: 1150px;
  }
}
.security-audit__img {
  margin: 40px 0;
  display: block;
}
</style>
