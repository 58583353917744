<template>
    <BuilderSection :designSection="designProjects"/>
</template>
<script>
import BuilderSection from './BuilderSection.vue'
import { mapState } from 'vuex'

export default {
    components:{
        BuilderSection
    },
    computed:{
        ...mapState(['designProjects'])

    }
}
</script>
